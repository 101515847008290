import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Button } from 'react-bootstrap';
import CrudFilterField from './CrudFilterField';
import { crud } from 'store/api';
import { getConfig } from 'config';

class CrudFilter extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      codebooks: {},
      filter: {},
      defaultFilter: {},
    };

    this.initFilter(props);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      this.initFilter(this.props);
    }
  }

  /**
   * Initializes the CRUD filter according to `props.config` (array).
   * Field config example:
   *
   * ```
   *  {
   *    name: 'name',
   *    type: 'text',
   *    field: 'name',
   *    label: 'Název',
   *  }
   * ```
   *
   * or using a codebook:
   *
   * ```
   *  {
   *    name: 'status',
   *    label: 'Stav',
   *    type: 'select',
   *    items: getConfig('products.types'),
   *    field: 'status',
   *  }
   * ```
   *
   * It is possible to quickly use a default config field, e.g.
   *
   * ```
   *  [ 'name' ]
   * ```
   *
   * or override some of the config settings:
   *
   * ```
   *  [{ override: 'progressionCompany', field: 'product.companyId' }]
   * ```
   *
   * also with custom override parameters, that the filter function may use:
   *
   * ```
   *  [{ override: 'progressionCompany', field: 'product.companyId', overrideParams: ['foo', 1] }]
   * ```
   *
   * Default available fields configs are:
   *
   * - `name` - by name
   * - `product` - products select box by productId
   * - `mortgageProducts` - products for mortgages (typeId = 6)
   * - `progressionProducts` - products for progression (typeId = 1)
   * - `company` - companies selectbox by companyId
   * - `mortgageCompany` - companies for mortgages (typeId = 6)
   * - `progressionCompany` - companies for progression (typeId = 1)
   * - `type` - by typeId
   *
   * @param {object} props
   */
  initFilter(props) {
    const { config = [], filter = {}, defaultFilter = {} } = props;
    const getLoanTypeId = loanType => _.get(getConfig('loans.types'), `${loanType}.type`);

    const configs = {
      name: () => ({
        name: 'name',
        type: 'text',
        field: 'name',
        label: 'Název',
      }),
      product: () => {
        this.loadCodebook('products', ({ id, name }) => ({ key: id, value: name }));
        return {
          name: 'productId',
          type: 'select',
          field: 'productId',
          label: 'Produkt',
          items: 'products',
        };
      },
      mortgageProduct: (loanType) => {
        const typeId = loanType ? getLoanTypeId(loanType) : 6;
        this.loadCodebook('products', ({ id, name, company }) => ({ key: id, value: `${company.name} ${name}` }), { typeId }, 'mortgageProducts');
        return {
          name: 'productId',
          type: 'select',
          field: 'productId',
          label: 'Produkt',
          items: 'mortgageProducts',
        };
      },
      progressionProduct: () => {
        this.loadCodebook('products', ({ id, name, company }) => ({ key: id, value: `${company.name} ${name}` }), { typeId: 1 }, 'progressionProducts');
        return {
          name: 'productId',
          type: 'select',
          field: 'productId',
          label: 'Produkt',
          items: 'progressionProducts',
        };
      },
      company: () => {
        this.loadCodebook('companies', ({ id, name }) => ({ key: id, value: name }));
        return {
          name: 'companyId',
          type: 'select',
          field: 'companyId',
          label: 'Producent',
          items: 'companies',
        };
      },
      mortgageCompany: (loanType) => {
        const typeId = loanType ? getLoanTypeId(loanType) : 6;
        this.loadCodebook('products', ({ company }) => ({ key: company.id, value: company.name }), { typeId }, 'mortgageCompanies');
        return {
          name: 'companyId',
          type: 'select',
          field: 'companyId',
          label: 'Producent',
          items: 'mortgageCompanies',
        };
      },
      progressionCompany: () => {
        this.loadCodebook('products', ({ company }) => ({ key: company.id, value: company.name }), { typeId: 1 }, 'progressionCompanies');
        return {
          name: 'companyId',
          type: 'select',
          field: 'companyId',
          label: 'Producent',
          items: 'progressionCompanies',
        };
      },
      type: () => {
        this.loadCodebook('types', ({ id, name }) => ({ key: id, value: name }));
        return {
          name: 'typeId',
          type: 'select',
          field: 'typeId',
          label: 'Typ',
          items: 'types',
        };
      },
    };

    this.config = config.map(c => {
      if (typeof c === 'string' || (c && c.override)) {
        if (c.override && configs[c.override]) {
          const overrideParams = c.overrideParams ? c.overrideParams : [];
          return {...configs[c.override](...overrideParams), ...c};

        } else if (configs[c]) {
          return configs[c]();

        } else {
          console.error(`No predefined filter "${c}"!`);
          return null;
        }
      } else {
        return c;
      }
    });

    this.setState({
      codebooks: {},
      filter,
      defaultFilter,
    });
  }

  loadCodebook(entity, transform, params = {}, key = null) {
    this.props.crudCache(entity).get({ ...params, itemsPerPage: 1000 }).then(( data ) => {
      let items = data.map(transform);
      items = _.uniqBy(_.sortBy(items, ({ value }) => _.toLower(_.deburr(value))), 'key');
      this.setState({ codebooks: { ...this.state.codebooks, ...{ [key || entity]: items } } });
    });
  }

  changeField(field, value, autoSubmit = false) {
    this.setState((state) => {
      state.filter[field] = value;
      return {..._.pickBy(state, (v) => v)};
    }, () => autoSubmit && this.apply());
  }

  getValue(field) {
    return _.get(this.state.filter, field, '') || '';
  }

  getFilterItems(filter) {
    if (!filter.items) {
      return [];
    }
    if (typeof filter.items === 'object') {
      return filter.items;
    }
    return _.get(this.state.codebooks, filter.items, []) || [];
  }

  apply() {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.state.filter);
    }
  }

  reset() {
    this.setState({ filter: {...this.state.defaultFilter} }, this.apply);
  }

  render() {
    return <div className="crud-filter">
      {this.config.map((filter) => {
        return filter &&
          <CrudFilterField key={filter.name || filter.field}
            {...filter}
            value={this.getValue(filter.field)}
            onChange={(v, submit = false) => this.changeField(filter.field, v, submit)}
            onSubmit={() => this.apply()}
            items={this.getFilterItems(filter)}
            />
      })}
      <Button style={{ marginTop: '.5rem' }} variant="secondary" onClick={() => this.apply()}>Filtrovat</Button>
      <Button style={{ marginTop: '.5rem' }} variant="link" onClick={() => this.reset()}>Reset</Button>
    </div>
  }

}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
    crudCache: (entity) => dispatch(crud(entity, true)),
  })
) (CrudFilter)
