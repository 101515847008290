/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
// import { formatBooleanValue } from 'services/utils';
import Aside from 'components/Aside.jsx';

import Type from './components/Type';
import EditTariff from './components/EditTariff';
import EditCategory from './components/EditCategory';
import EditParameter from './components/EditParameter';
import { showSuccessMessage } from 'services/messages';

class Structure extends CrudComponent {

  constructor(props) {
    super(props);
    this.initEntities('', 'structure/tree');

    this.state = {
      ...super.state,

      edit: {
        type: null,
        id: null,
        path: null,
        params: null,
      },

    }

    this.onEdit.bind(this);
    this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.showLoading();
    return this.api.get(this.getUrlParams())
      .then((data) => this.setState({ data }))
      .finally(() => this.hideLoading());
  }

  loadDataPartial(edit) {
    const { type, id, path } = edit;

    if (!id || !path) {
      return this.loadData();
    }

    const dataPath = path.split('.');
    dataPath.splice(-1);

    this.showLoading();
    return this.api.getApi().get('structure/tree/partial', { params: this.getUrlParams({ type, id })} )
      .then((data) => this.setState(state => _.set(state, `data.${dataPath.join('.')}`, data)))
      .finally(() => this.hideLoading());
  }

  getTitle() {
    return 'Struktura';
  }

  onEdit(id, type, path, params = null) {
    this.setState({ edit: { type, id, path, params } });
  }

  closeEdit() {
    this.setState({ edit: { type: null, id: null, path: null, params: null }})
  }

  getColumns() {
    return [
      { text: 'Typ', dataField: 'tariffCategory.tariff.type', formatter: ({id, name}) => `(${id}) ${name}` },
      { text: 'Tarif', dataField: 'tariffCategory.tariff', formatter: ({id, name}) => `(${id}) ${name}` },
      { text: 'Kategorie', dataField: 'tariffCategory.category', formatter: ({id, name}) => `(${id}) ${name}`},
      { text: 'Parametr', dataField: 'parameter', formatter: ({id, name}) => `(${id}) ${name}` },
      { text: '', isDummyField: true, dataField: '_actions',
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3">
              <Icon icon='pen' />
            </Link>}
          </div>) }
    ];
  }

  getFilter() {
    return [
      { override: 'type', field: 'tariffCategory.tariff.typeId' },
      { type: 'text', label: 'Tarif', field: 'tariffCategory.tariff.name' },
      { type: 'text', label: 'Kategorie', field: 'tariffCategory.category.name' },
      { type: 'text', label: 'Parametr', field: 'parameter.name' },
    ];
  }


  /* DRAG'n'DROP */
  move(source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [ removed ] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    return [ sourceClone, destClone ];
  }

  reorder(list, start, end) {
    const result = Array.from(list);
    const [ removed ] = result.splice(start, 1);

    result.splice(end, 0, removed);

    return result;
  };

  parseDroppableId(droppableId) {
    const s = droppableId.split('/');
    const [ index, id ] = s[1].split('-');
    const path = s[2];

    switch (s[0]) {
      case 'type':
        return {
          type: 'tariffs',
          id, // = typeId
          index, // = position in array
          path, // = path in tree
        };

      case 'tariff':
        return {
          type: 'categories',
          id, // = tariffId
          index, // = position in array
          path, // = path in tree
        };

      case 'category':
        return {
          type: 'parameters',
          id, // = tariffCategoryId
          index, // = position in array
          path, // = path in tree
        };

      default:
        throw new Error(`Unsupported d'n'd type "${s[0]}"!`);
    }
  }

  saveSort(type, id, items) {
    return this.api.getApi().post('structure/order', { type, id, items });
  }

  saveMove(type, id, idFrom, idTo) {
    return this.api.getApi().post('structure/move', { type, id, idFrom, idTo });
  }

  onDragEnd(result) {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // change sort within one group
    if (source.droppableId === destination.droppableId) {
      const { type, id, path } = this.parseDroppableId(source.droppableId);

      const items = this.reorder(
        _.get(this.state, `data.${path}`),
        source.index,
        destination.index
      );

      this.setState(state => _.set(state, `data.${path}`, items));

      this.showLoading();
      this.saveSort(type, id, _.map(items, ({ id }) => id))
        .then(({ data }) => data.ok && showSuccessMessage('Nové pořadí uloženo'))
        .finally(() => this.hideLoading());

    } else {
      // move item to another group
      const { type, id: idFrom, path: pathFrom } = this.parseDroppableId(source.droppableId);
      const { id: idTo, path: pathTo } = this.parseDroppableId(destination.droppableId);

      const result = this.move(
        _.get(this.state, `data.${pathFrom}`),
        _.get(this.state, `data.${pathTo}`),
        source,
        destination
      );

      const [ itemsFrom, itemsTo ] = result;
      const [ dragType, dragId ] = draggableId.split('/');

      this.setState(state => {
        _.set(state, `data.${pathFrom}`, itemsFrom);
        _.set(state, `data.${pathTo}`, itemsTo);
        return state;
      });

      this.showLoading();
      Promise.all([
        this.saveMove(dragType, dragId, idFrom, idTo),
        this.saveSort(type, idFrom, _.map(itemsFrom, ({ id }) => id)),
        this.saveSort(type, idTo, _.map(itemsTo, ({ id }) => id)),
      ])
        .then(() => showSuccessMessage('Přesunuto'))
        .finally(() => this.hideLoading());
    }
  }

  render() {
    const { edit } = this.state;
    const { history } = this.props;

    return (
      <div>
        <Aside className="aside--sm" on={!!edit.type}>
          {edit.type === 'tariff' &&
            <EditTariff aside id={edit.id} params={edit.params} entity="structure/tariffs" history={history}
              onSave={() => this.loadDataPartial(edit).then(() => this.closeEdit() )}
              onClose={() => this.closeEdit() }/>
          }
          {edit.type === 'category' &&
            <EditCategory aside id={edit.id} params={edit.params} entity="structure/tariff_categories" history={history}
              onSave={() => this.loadDataPartial(edit).then(() => this.closeEdit() )}
              onClose={() => this.closeEdit() }/>
          }
          {edit.type === 'parameter' &&
            <EditParameter aside id={edit.id} params={edit.params} entity="structure/tariff_category_parameters" history={history}
              onSave={() => this.loadDataPartial(edit).then(() => this.closeEdit() )}
              onClose={() => this.closeEdit() }/>
          }
        </Aside>

        <div className="navbar m-0 pr-2">
          <h1>
            {this.getTitle()}
          </h1>
          <a className="structure-sync" href="#" title="Znovu načíst data" onClick={() => this.loadData()}><Icon icon="sync" /></a>
        </div>

        <div className="structure">
          <DragDropContext onDragEnd={(r) => this.onDragEnd(r)}>
            {this.getData().map( (type, index) =>
              <Type key={type.type.id} path={index} index={index} {...type} onEdit={(id, type, path, params = null) => this.onEdit(id, type, path, params)} />
            )}
          </DragDropContext>
        </div>

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (Structure)
