/* eslint-disable import/no-anonymous-default-export */

export const ROLE_USER = 'ROLE_USER';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_ASSISTANT = 'ROLE_ASSISTANT';

/*

  ACL CRUD actions:
  - "*.create" = new
  - "*.read" = list + detail
  - "*.update" = edit
  - "*.delete"

  => "*" = entity

*/

export default {
  [ROLE_USER] : {
    allow: [
      'login',
      'logout',
      'password.change',
    ],
    deny: 'all',
  },

  [ROLE_ASSISTANT] : {
    allow: [
      'mortgage/discounts.read', 'mortgage/discounts.update',
      'mortgage/fixes.read',
      'mortgage/purposes.read',
      'mortgage/rates.read', 'mortgage/rates.update', 'mortgage/rates.create', 'mortgage/rates.delete',

      'americanMortgage/discounts.read', 'americanMortgage/discounts.update',
      'americanMortgage/fixes.read',
      'americanMortgage/purposes.read',
      'americanMortgage/rates.read', 'americanMortgage/rates.update', 'americanMortgage/rates.create', 'americanMortgage/rates.delete',

      'consumerCredit/discounts.read', 'consumerCredit/discounts.update',
      'consumerCredit/fixes.read',
      'consumerCredit/purposes.read',
      'consumerCredit/rates.read', 'consumerCredit/rates.update', 'consumerCredit/rates.create', 'consumerCredit/rates.delete',

      'buildingLoan/discounts.read', 'buildingLoan/discounts.update',
      'buildingLoan/fixes.read',
      'buildingLoan/purposes.read',
      'buildingLoan/rates.read', 'buildingLoan/rates.update', 'buildingLoan/rates.create', 'buildingLoan/rates.delete',
    ],
    deny: 'all',
  },

  [ROLE_ADMIN] : {
    allow: 'all',
    deny: null,
  },

};
