import React, { PureComponent } from "react";
import { toast, ToastContainer } from 'react-toastify';
// import { Transition } from 'react-transition-group';

import { onUpdateMessages, dismissMessages } from 'services/messages';

export default class ToastMessages extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      messages: [],
    };
  }

  /**
   * Configure toast messages container and subscribe
   * to toast messages update
   */
  componentDidMount() {
    toast.configure({
      newestOnTop: true,
      autoClose: 8000,
    });

    onUpdateMessages((messages) => this.setState({ messages }));
  }

  /**
   * Dismiss all toast messages
   */
  dismiss(e) {
    e.preventDefault();
    this.setState({ messages: [] });
    dismissMessages();
  }

  /**
   * Render toast messages dismisser and container
   */
  render() {
    // const { messages } = this.state;

    // move the dismisser up/down and fade in/out
    // const transitionStyles = {
    //   entering: { opacity: 1, display: 'block', transform: 'translateY(-2rem)' },
    //   entered: { opacity: 1, display: 'block', transform: 'translateY(0)' },
    //   exiting: { opacity: 0, display: 'block', transform: 'translateY(-2rem)' },
    //   exited: { opacity: 0, display: 'none', transform: 'translateY(-2rem)' },
    // };

    return <div className="app-messages">
      {/* <Transition in={messages.length > 0} timeout={200}>
        {state => (
          <div className="mapp-messages-dismiss" style={transitionStyles[state]}>
            <a href="./#" onClick={(e) => this.dismiss(e)}>Zavřít vše</a>
          </div>
        )}
      </Transition> */}
      <ToastContainer containerId="app-messages" />
    </div>;
  }
}
