import React from 'react';

import _ from 'lodash';

import Forms from 'components/Forms.jsx';

class ProgressionValueEdit extends React.Component {

  constructor(props) {
    super(props);

    const { id = null, degree = null, value = null } = (props.value || {});

    this.state = {
      id,
      degree,
      value,
    };

  }

  set(key, value) {
    this.setState(_.set(this.state, key, Number(value)));
  }

  cancel = () => this.props.onClose();

  save = () => this.props.onClose(this.state);

  render() {
    const { degree, value } = this.state;

    return <tr>
      <td colspan="2" style={{ padding: '.25rem .75rem', verticalAlign: 'center' }}>
        <div className="d-flex">

          <Forms.Input value={degree}
            type="number"
            style={{ width: '50px', marginRight: '.5rem' }}
            onEnterKey={() => this.save()}
            onChange={v => this.set('degree', v)} />

          <Forms.Input value={value}
            type="number"
            style={{ width: '120px' }}
            onEnterKey={() => this.save()}
            onChange={v => this.set('value', v)} />

        </div>
      </td>
      <td style={{ border: 0 }}>
        <button type="button" className="btn btn-secondary btn-sm mr-2" onClick={() => this.cancel()}>Zrušit</button>
        <button type="button" className="btn btn-primary btn-sm" onClick={() => this.save()}>Uložit</button>
      </td>
    </tr>;

  }

}

export default ProgressionValueEdit;
