import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Alert } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import Forms from 'components/Forms.jsx';
import LabelValue from 'components/LabelValue.jsx';
import ConfirmDialog from 'components/ConfirmDialog.jsx';

import { crud } from 'store/api';
import { parseDecimal } from 'services/utils';
import { showSuccessMessage } from 'services/messages';
import { getLoanByType } from 'config/loans';

class Edit extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      editItem: {},
      currentCompanyId: null,
      editItemDiscounts: {},
      editItemFixes: {},
      editItemPurposes: {},
    };

    this.products = props.crudCache('products');
    this.discounts = props.crudCache('mortgage/discounts');
    this.fixes = props.crudCache('mortgage/fixes');
    this.purposes = props.crudCache('mortgage/purposes');

    this.handleSuccessfulCreate.bind(this);
  }

  componentDidMount() {
    this.loadData();

    this.products.get({ typeId: getLoanByType('consumerCredit').type, itemsPerPage: 1000, 'order[company.name]': 'asc', 'order[product.name]': 'asc' }).then(this.setCodebookData('products', ({ id, name, company }) => {
      return { key: id, value: `${company.name} ${name}`, companyId: company.id };
    }));
    this.discounts.get({ itemsPerPage: 1000, 'product.typeId': getLoanByType('consumerCredit').type }).then(this.setCodebookData('discounts', ({ id, name, product, rate }) => {
      return { key: id, value: `${_.get(product, 'company.name')} – ${name} - ${rate} %`, companyId: _.get(product, 'company.id') };
    }));
    this.fixes.get({ itemsPerPage: 1000, 'product.typeId': getLoanByType('consumerCredit').type }).then(this.setCodebookData('fixes', ({ id, product, length}) => {
      return { key: id, value: `${_.get(product, 'company.name')} - ${length} R`, companyId: _.get(product, 'company.id') };
    }));
    this.purposes.get({ itemsPerPage: 1000 }).then(this.setCodebookData('purposes'));

  }

  getId() {
    const id = _.get(this.props, 'match.params.id');
    if (!id) {
      return _.get(this.props, 'id');
    }
    return id;
  }

  loadData() {
    const id = this.getId();
    if (id) {
      this.loadItem(id);
    }
  }

  loadItem(id) {
    this.showLoading();
    return this.api.find(id)
      .then(( data ) => {
        let editItem;
        if (data) {
          editItem = {
            ...data,
          };
        }
        const currentCompanyId = _.get(editItem, 'product.company.id');
        this.setState({
          editItem,
          currentCompanyId,
          editItemDiscounts: _.chain(editItem).get('discounts').keyBy('id').mapValues(() => true).value(),
          editItemFixes: _.chain(editItem).get('fixes').keyBy('id').mapValues(() => true).value(),
          editItemPurposes: _.chain(editItem).get('purposes').keyBy('id').mapValues(() => true).value(),
        });
      })
      .finally(() => this.hideLoading());
  }

  getTitle() {
    const { editItem } = this.state;
    const val = `${_.get(editItem, 'product.company.name')} ${_.get(editItem, 'product.name')} / ${_.get(editItem, 'rate')} %`;

    return !this.isNew() && editItem ?
      (this.props.duplicate ? `${getLoanByType('consumerCredit').title} / Duplikace sazby ${val}` : `Hypotéky / Sazba ${val}`) :
      'Nová sazba';
  }

  prepareItemForSave(item) {
    const { productId, amountFrom, amountTo, ltvFrom, ltvTo, rate, maturityFrom, maturityTo,
      validFrom, expires } = item;

    const toInt = v => parseInt(v);

    const value = {
      ...item,
      productId: productId ? parseInt(productId) : null,
      amountFrom: parseInt(amountFrom),
      amountTo: parseInt(amountTo),
      maturityFrom: parseInt(maturityFrom),
      maturityTo: parseInt(maturityTo),
      ltvFrom: parseDecimal(ltvFrom),
      ltvTo: parseDecimal(ltvTo),
      rate: parseDecimal(rate),
      product: null,
      purposes: _.chain(this.state.editItemPurposes).pickBy(Boolean).keys().value().map(toInt),
      fixes: _.chain(this.state.editItemFixes).pickBy(Boolean).keys().value().map(toInt),
      discounts: _.chain(this.state.editItemDiscounts).pickBy(Boolean).keys().value().map(toInt),
      validFrom: validFrom && validFrom.toUTCString ? validFrom.toUTCString() : validFrom,
      expires: expires && expires.toUTCString? expires.toUTCString() : expires,
    };

    if (this.props.duplicate) {
      value.id = null;
      // value.hash = null;
    }

    return value;
  }

  getDiscounts() {
    return _.filter(this.state.discounts, { companyId: this.state.currentCompanyId } );
  }

  getFixes() {
    return _.filter(this.state.fixes, { companyId: this.state.currentCompanyId } );
  }

  getPurposes() {
    return _.get(this.state, 'purposes') || [];
  }

  handleSuccessfulCreate({ id }) {
    if (id) {
      this.props.duplicate && showSuccessMessage('Sazba duplikována');
      if (this.props.aside) {
        this.loadItem(id);
      } else {
        return super.redirect(id);
      }
    }
  }

  render() {
    const { editItem } = this.state;
    const onChange = field => v => this.changeDetailItem(field, v);
    const onChangeProduct = field => v => {
      this.changeDetailItem(field, v);
      this.setState({
        // eslint-disable-next-line eqeqeq
        currentCompanyId: _.get(_.find(this.state.products, ({ key }) => key == v), 'companyId'),
        editItemDiscounts: {},
        editItemFixes: {},
        editItemPurposes: {},
      });
    };
    const get = field => _.get(editItem, field);

    return <div>

      <ConfirmDialog {...this.state.confirm} />

      <div className="navbar justify-content-start">
        <div className="col-auto">
          <Link className="p-4" to={this.getEndpoint()} onClick={() => {
            if (this.props.aside && this.props.onClose) {
              this.props.onClose();
              return false;
            }
          }}>
            <Icon icon={ this.props.aside ? 'times' : 'chevron-left' } />
          </Link>
        </div>
        <h1 className="ml-2">{this.getTitle()}</h1>
      </div>

      <div className="container pt-4">

        {this.props.duplicate && <Alert variant="info">💡 Kliknutím na tlačítko "Uložit" se hypoteční sazba duplikuje s nastavenými daty.</Alert>}

        <Card body>
          <div className="row">

            <LabelValue className="col-sm-4" label="Produkt">
              <Forms.Select value={get('productId')}
                placeholder="= Vyberte ="
                items={this.state.products}
                onChange={onChangeProduct('productId')}
                />
            </LabelValue>

            <LabelValue className="col-sm-2" label="Sazba [%]">
              <Forms.Input type="number" format="float" value={get('rate')} onChange={onChange('rate')} />
            </LabelValue>

            <LabelValue className="col-sm-2" label="Pořadí">
              <Forms.Input type="number" format="integer" value={get('sort')} onChange={onChange('sort')} />
            </LabelValue>
          </div>
          <div className="row">

            <LabelValue className="col-sm-3" label="Splatnost od [roky]">
              <Forms.Input type="number" format="integer" value={get('maturityFrom')} onChange={onChange('maturityFrom')} />
            </LabelValue>

            <LabelValue className="col-sm-3" label="Splatnost do [roky]">
              <Forms.Input type="number" format="integer" value={get('maturityTo')} onChange={onChange('maturityTo')} />
            </LabelValue>

            <LabelValue className="col-sm-3" label="LTV od [%]">
              <Forms.Input type="number" format="float" value={get('ltvFrom')} onChange={onChange('ltvFrom')} />
            </LabelValue>

            <LabelValue className="col-sm-3" label="LTV do [%]">
              <Forms.Input type="number" format="float" value={get('ltvTo')} onChange={onChange('ltvTo')} />
            </LabelValue>

            <LabelValue className="col-sm-3" label="Částka od [Kč]">
              <Forms.Input type="number" format="integer" value={get('amountFrom')} onChange={onChange('amountFrom')} />
            </LabelValue>

            <LabelValue className="col-sm-3" label="Částka do [Kč]">
              <Forms.Input type="number" format="integer" value={get('amountTo')} onChange={onChange('amountTo')} />
            </LabelValue>

          </div>
          <div className="row">

            <LabelValue className="col-sm-3" label="Platnost od">
              <Forms.DatePicker value={get('validFrom')} onChange={onChange('validFrom')} />
            </LabelValue>

            <LabelValue className="col-sm-3" label="Expirace">
              <Forms.DatePicker value={get('expires')} onChange={onChange('expires')} />
            </LabelValue>

            <div className="col-sm-12 mb-3">
              <h5 className="text-uppercase font-weight-bold mb-2">Účely</h5>
              <div className="row">
                {this.getPurposes().map( purpose => {
                  return <div key={purpose.key} className="col-sm-auto form-group m-0">
                    <Forms.Checkbox label={purpose.value}
                      value={_.get(this.state.editItemPurposes, purpose.key)}
                      onChange={(v) => this.handleStateChange(`editItemPurposes.${purpose.key}`, v)} />
                    </div>
                })}
              </div>
            </div>

            <div className="col-sm-6">
              <h5 className="text-uppercase font-weight-bold mb-2">Fixy</h5>
              <div className="row">
                {this.getFixes().map( fix => {
                  return <div key={fix.key} className="col-sm-12 form-group m-0">
                    <Forms.Checkbox label={fix.value}
                      value={_.get(this.state.editItemFixes, fix.key)}
                      onChange={(v) => this.handleStateChange(`editItemFixes.${fix.key}`, v)} />
                    </div>
                })}
              </div>
            </div>

            <div className="col-sm-6">
              <h5 className="text-uppercase font-weight-bold mb-2">Slevy</h5>

              <div className="row">
                {this.getDiscounts().map( discount => {
                  return <div key={discount.key} className="col-sm-12 form-group m-0">
                    <Forms.Checkbox label={discount.value}
                      value={_.get(this.state.editItemDiscounts, discount.key)}
                      onChange={(v) => this.handleStateChange(`editItemDiscounts.${discount.key}`, v)} />
                    </div>
                })}
              </div>

            </div>


            <div className="form-group mb-0 col-sm-12 text-right pt-4">
              <button type="button" className="btn btn-primary font-weight-bold mr-2" onClick={() => this.save()}>
                Uložit
              </button>
              <Link to={this.getEndpoint(this.getId())} className="btn btn-secondary font-weight-bold" onClick={(e) => {
                if (this.props.aside && this.props.onClose) {
                  this.props.onClose();
                  e.preventDefault();
                }
              }}>
                Zrušit
              </Link>
            </div>
          </div>
        </Card>

        {this.getId() && this.canDo('delete') && !this.props.duplicate && <div className="text-center p-3">
          <button className="btn btn-outline-danger p-2" onClick={() => this.delete()}>Smazat</button>
        </div>}

      </div>

    </div>;
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
    crudCache: (entity) => dispatch(crud(entity, { cache: true })),
  })
) (Edit)
