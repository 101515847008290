import axios from 'axios';
import _ from 'lodash';

import { getLocalToken } from 'store/user';
import ArrayPaginator from 'utils/ArrayPaginator';
import { showErrorMessage } from 'services/messages';

// const API_BASE_URL = 'http://localhost:8000';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const getAuthorizationHeader = () => {
  const token = getLocalToken();
  return token ? {
    'Authorization': `Bearer ${token}`,
  } : {};
};

/** COMMON API ERROR HANDLER */
const handleError = (error, data) => {
  console.error('API error', error.toJSON());

  if (error.response) {
    error = error.response;
    if (error.status === 500) {
      // throw Error('GeneralError');
      error = {message: 'GeneralError', ...error};
    }
    // if (error.status === 401) {
    //   console.log('Auth error');
    //   removeLocalToken();
    // }
    // @TODO, tbd other handlings

    if (error.data && error.data.message) {
      showErrorMessage(error.data.message);
    }

  } else {
    console.log('Unknown error', error);
  }

  return Promise.reject(error.data || error);
}


const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    ...getAuthorizationHeader(),
  },
});


api.interceptors.response.use((res) => {
  const { data, headers } = res;

  if (data && data.status && data.status >= 400) {
    return Promise.reject(data);
  }

  if (_.isArray(data) && headers['x-pagination-total-count']) {
    const paginatedData = new ArrayPaginator(...data);
    paginatedData.setPagination({
      page: Number(headers['x-pagination-current-page']),
      last: Number(headers['x-pagination-last-page']),
      limit: Number(headers['x-pagination-items-per-page']),
      count: Number(headers['x-pagination-total-count']),
    });
    return Promise.resolve(paginatedData);
  }

  return Promise.resolve(data);
}, handleError);

export default api;
