/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Truncate from 'react-truncate';
import { preventDefault } from 'services/utils';

const ReadMore = function({ children, lines = 1 }) {
  const [expanded, setExpanded] = useState(false);

  return  <>
    {!expanded && <Truncate className="read-more" lines={lines}
      ellipsis={(
        <span>&hellip; <a href="#" onClick={preventDefault(() => setExpanded(true))}>více</a></span>
      )}>
      { children }
    </Truncate>}
    {expanded && (
      <>{ children } <span> <a href="#" onClick={preventDefault(() => setExpanded(false))}>méně</a></span></>
    )}
  </>
}

export default ReadMore;
