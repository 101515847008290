import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import LabelValue from 'components/LabelValue';
import { crud } from 'store/api';

class Detail extends CrudComponent {

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return this.api.find(this.getId()).then(( data ) => this.setState({ item: data }));
  }

  render() {
    const { item } = this.state;

    return ( item &&
      <div>

        <div className="navbar justify-content-start">
          <div className="col-auto">
            <Link className="p-4" to={this.getEndpoint()}>
              <Icon icon="chevron-left" />
            </Link>
          </div>
          <h1 className="ml-2">Produktová skupina – {_.get(item, 'company.shortName')} {_.get(item, 'name')}</h1>
        </div>

        <div className="container pt-4">

          <Card body>
            <div className="row">
              <div className="col-10">
                <div className="row">
                  <LabelValue className="col-sm-4" label="Název" value={item.name} />
                  <LabelValue className="col-sm-4" label="Typ" value={_.get(item, 'type.name')} />
                  <LabelValue className="col-sm-4" label="Společnost" value={_.get(item, 'company.name')} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group mb-0 text-right">
                  {this.canDo('update') && <Link to={this.getEndpoint(`${this.getId()}/edit`)} className="btn btn-secondary font-weight-bold">
                    <Icon icon='pen' className='mr-1' />
                    Upravit
                  </Link>}
                </div>
              </div>
            </div>
          </Card>

        </div>
      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (Detail)

