/* eslint-disable import/no-anonymous-default-export */

// ACTIONS

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const showLoading = () => {
  return { type: SHOW_LOADING };
}

export const hideLoading = () => {
  return { type: HIDE_LOADING };
}

export default (state = false, action) => {
  const { type } = action;

  const reducer = {
    [SHOW_LOADING]: () => true,
    [HIDE_LOADING]: () => false,
  };

  return reducer[type] ? reducer[type]() : state;
}
