import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';

import CrudComponent from 'components/CrudComponent.jsx';
import ReadMore from 'components/ReadMore.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
import { formatDate, formatDecimal } from 'services/utils';
import { getLoanByType } from 'config/loans';

class List extends CrudComponent {

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(props) {
    super.checkUpdatePage(props);
  }

  loadData() {
    this.showLoading();
    const params = this.getUrlParams({
      'product.typeId': getLoanByType('mortgage').type
    });
    return this.api.get(params)
      .then((data) => this.setData(data))
      .finally(() => this.hideLoading());
  }

  getTitle() {
    return `${getLoanByType('mortgage').title} / slevy ${this.getTotalFormatted()}`;
  }

  getFilter() {
    return [
      'name',
      { name: 'rate', label: 'Sazba', type: 'text', field: 'rate' },
      // { name: 'isDeal', label: 'Akce', type: 'select', items: [{ key: 1, value: 'ano' }, { key: 0, value: 'ne' }], field: 'isDeal' },
      // { name: 'type', label: 'Typ', type: 'select', items: getConfig('mortgage.discounts.types'), field: 'type' },
      { override: 'mortgageCompany', field: 'product.companyId', overrideParams: ['mortgage'] },
      { override: 'mortgageProduct', overrideParams: ['mortgage'] },
      { name: 'expiration', label: 'Expirace do', type: 'date', field: 'expiration[before]' },
    ];
  }

  getColumns() {
    return [
      { text: 'Název', dataField: 'name', headerAttrs: { width: 150 }, sort: true },
      { text: 'Sazba', dataField: 'rate', sort: true, headerAttrs: { width: 100 }, formatter: (v) => `${formatDecimal(v, null)} %` },
      { text: 'Popis', dataField: 'description', sort: true, formatter: (v) => <ReadMore lines={2}>{v}</ReadMore> },
      // { text: 'Akce', dataField: 'isDeal', sort: true, formatter: (v) => formatBooleanValue(v) },
      // { text: 'Typ', dataField: 'type', sort: true, formatter: (v) => formatCodebookValue(v, getConfig('mortgage.discounts.types')) },
      { text: 'Produkt', dataField: 'product.name', headerAttrs: { width: 200 }, sort: true, formatter: (__, {product}) => product ? `${_.get(product, 'company.shortName')} ${_.get(product, 'name')}` : '' },
      { text: 'Expirace', dataField: 'expiration', headerAttrs: { width: 150 }, sort: true, formatter: (v) => formatDate(v) },
      { text: '', isDummyField: true, headerAttrs: { width: 200 }, dataField: '_actions',
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3">
              <Icon icon='pen' />
            </Link>}
          </div>) }
    ];
  }

  render() {
    return (
      <div>
        <div className="navbar m-0 pr-2">
          <h1>{this.getTitle()}</h1>
          {this.canDo('create') && <Link to={this.getEndpoint('new')} className="primary-button">+ nový</Link>}
        </div>

        {this.renderFilter()}

        <BootstrapTable
          classes="table-sm"
          bootstrap4 hover remote
          bordered={false}
          keyField="id"
          data={this.getData()}
          columns={this.getColumns()}
          onTableChange={this.onTableChange()}
        />

        {this.renderPagination()}

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (List)
