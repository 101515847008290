import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Forms from 'components/Forms.jsx';

import { passwordRecover, setPasswordRecover } from 'store/api';

class PasswordRecover extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      username: null,
      requestSuccessful: false,

      token: null,
      password: null,
      passwordCheck: null,

      errorMessage: null,
    };

  }

  componentDidMount() {
    const { token } = _.get(this.props, 'match.params');
    token && this.setState({ token });
  }

  recover() {
    this.setState({ errorMessage: null })

    const { username } = this.state;
    return passwordRecover(username)
      .then(() => this.setState({ requestSuccessful: true }))
      .catch((error) => {
        const { message } = error;
        if (message) {
          this.setState({ errorMessage: message });
        }
        console.error('error', error);
      });
  }

  doPasswordsMatch() {
    const { token, password, passwordCheck } = this.state;
    return token && password && password === passwordCheck;
  }

  setNewPassword() {
    if (this.doPasswordsMatch()) {
      const { token, password } = this.state;
      return setPasswordRecover(token, password)
        .then(() => {
          window.location = '/';
        })
        .catch((error) => {
          const { message } = error;
          if (message) {
            this.setState({ errorMessage: message });
          }
          console.log('error', error);
        });
      }
  }


  render() {
    const { token } = this.state;

    return (
      <div className="container login-box">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-4">Obnova zapomenutého hesla</h2>

                {!token && <><p>
                  Přihlašovací jméno (e-mail):
                  <Forms.Input name="username"
                    onEnterKey={() => this.recover()}
                    onChange={(value) => this.handleStateChange('username', value)} />
                </p>
                {/* <p>Na uvedený e-mail bude zaslán odkaz přes který si nastavíte nové heslo.</p> */}
                {this.state.errorMessage && <p className="text-danger">
                  {this.state.errorMessage}
                </p> }
                {this.state.requestSuccessful && <p className="text-success">
                  Na uvedený e-mail byl odeslán odkaz pro obnovu hesla.
                </p> }
                <p className="text-center">
                  <button className="btn btn-lg btn-primary mt-2" onClick={() => this.recover()}>Zaslat odkaz pro obnovení hesla</button>
                </p></>}

                {token && <>
                <p>
                  Nové heslo:
                  <Forms.Input name="password" type="password"
                    onEnterKey={() => this.setNewPassword()}
                    onChange={(value) => this.handleStateChange('password', value)} />
                </p>
                <p>
                  Potvrzení hesla:
                  <Forms.Input name="passwordCheck" type="password"
                    onEnterKey={() => this.setNewPassword()}
                    onChange={(value) => this.handleStateChange('passwordCheck', value)} />
                </p>
                {this.state.errorMessage && <p className="text-danger">
                  {this.state.errorMessage}
                </p> }
                <p className="text-center">
                  <button disabled={!this.doPasswordsMatch()} className="btn btn-lg btn-primary mt-2"
                    onClick={() => this.setNewPassword()}>Nastavit nové heslo</button>
                </p></>}

                <p className="text-center">
                  <Link to="/login" className="btn btn-link">Zpět na přihlášení</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default PasswordRecover;
