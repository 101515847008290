import React, { useState } from 'react';
import _ from 'lodash';

import Icon from 'components/Icon';
import Parameter from './Parameter';

const Category = ({ products, productsData, path, category, onEdit }) => {

  const [ isOpen, setIsOpen ] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return <>
    <tr>
      <th className="pl-2" onClick={toggle}>
        <span className="structure-item-control">
          {isOpen ? <Icon icon="minus-square" /> : <Icon icon="plus-square" />}
        </span>
        {_.get(category, 'category.name')}
      </th>
      {_.map(products, id => <td key={id}></td>)}
    </tr>

    {isOpen && category.parameters.map(( parameter, index ) =>
      <Parameter products={products} productsData={productsData} key={index} parameter={parameter} path={`${path}.parameters.${index}`} onEdit={onEdit} />
    )}

  </>

}

export default Category;
