/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

// ACTIONS

export const SET_CACHE = 'SET_CACHE';

export const GET_CACHE = 'GET_CACHE';

export const CLEAR_CACHE = 'CLEAR_CACHE';

export const set = (name, value) => {
  return { type: SET_CACHE, payload: { name, value } };
}

export const get = (name) => {
  return { type: GET_CACHE, payload: { name } };
}

export const clear = (name) => {
  return { type: CLEAR_CACHE, payload: { name } };
}

// STORE

export default (state = [], action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_CACHE]: (newState, { name, value }) => {
      return _.set(newState, name, value);
    },
    [GET_CACHE]: (newState, { name }) => {
      return _.get(newState, name);
    },
    [CLEAR_CACHE]: (newState, { name }) => {
      return _.set(newState, name, null);
    },
  };

  return reducer[type] ? reducer[type](state, payload, action) : state;
}

