/* eslint-disable import/no-anonymous-default-export */

import _ from 'lodash';
import api from 'services/api';
import CrudApi from 'utils/CrudApi';

// ACTIONS
export const SET_API = 'SET_API';

export const setApi = (entity, api) => {
  return {type: SET_API, payload: { entity, api }};
}

export const crud = (entity, properties = {}) => {
  return (dispatch, getState) => {
    let apiInstance = _.get(getState(), `api.${entity}`);

    if (!apiInstance) {
      apiInstance = new CrudApi(api, entity, properties);
    }

    dispatch(setApi(entity, apiInstance));

    return apiInstance;
  }
}

export const getCurrentUser = () => {
  return api.get('user/me');
}

export const postLogin = (login, password) => {
  return api.post('auth/login', { login, password });
}

export const postRefresh = () => {
  return api.post('auth/refresh', {});
}

export const postLogout = () => {
  return api.post('auth/logout');
}

export const passwordRecover = (login) => {
  return api.post('auth/recover_password', { login });
}

export const setPasswordRecover = (token, password) => {
  return api.post('auth/set_recover_password', { token, password });
}

export const getGravatarUrl = (hash) => {
  const baseUrl = `https://cs.gravatar.com/${hash}.json`;
  return api.get(baseUrl)
    .then(({ entry }) => _.get(entry, '0.thumbnailUrl'));
}

export default (state = {}, action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_API]: (state, { entity, api }) => {
      state = _.set(state, entity, api);
      return state;
    },
  };

  return reducer[type] ? reducer[type](state, payload, action) : state;
}
