import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import CrudComponent from 'components/CrudComponent.jsx';
import Aside from 'components/Aside.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
import { formatDate, formatDecimal, formatNumber } from 'services/utils';
import { getLoanByType } from 'config/loans';

import Edit from './Edit';

class List extends CrudComponent {

  // http://www.pojistne-podminky.cz/admin/rates

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      editId: null,
      editDuplicate: false,
      sort: {
        'order[sort]': 'asc',
      },
      isCompanySelected: false,
    };

  }

  componentDidMount() {
    const companyId = this.getParamFromUrl('product.companyId');

    if (companyId) {
      this.handleStateChange('filter', { ...this.state.filter, 'product.companyId': companyId } )
        .then(() => this.loadData());
    } else {
      this.loadData();
    }
  }

  componentDidUpdate(props) {
    super.checkUpdatePage(props);
  }

  loadData() {
    const params = this.getUrlParams({
      'product.typeId': getLoanByType('mortgage').type
    });
    if (!params['product.companyId']) {
      this.state.isCompanySelected && this.setState({ isCompanySelected: false });

    } else {
      !this.state.isCompanySelected && this.setState({ isCompanySelected: true });

      this.showLoading();
      return this.api.get(params)
        .then((data) => this.setData(data))
        .finally(() => this.hideLoading());
    }
  }

  onSave() {
    this.setState({ editId: null, editDuplicate: false });
    this.loadData();
  }

  getTitle() {
    return `${getLoanByType('mortgage').title} / sazby ${this.getTotalFormatted()}`;
  }

  getColumns() {
    return [
      { text: 'Poř.', dataField: 'sort', sort: true, headerAttrs: { width: 60 } },
      { text: 'Producent', dataField: 'product.company.name', sort: true },
      { text: 'Sazba', dataField: 'rate', sort: true, formatter: (v) => `${formatDecimal(v)} %` },
      { text: 'Splatnost od', dataField: 'maturityFrom', sort: true, formatter: formatNumber },
      { text: 'Splatnost do', dataField: 'maturityTo', sort: true, formatter: formatNumber },
      { text: 'LTV od', dataField: 'ltvFrom', sort: true, formatter: formatDecimal },
      { text: 'LTV do', dataField: 'ltvTo', sort: true, formatter: formatDecimal },
      { text: 'Částka od', dataField: 'amountFrom', sort: true, formatter: (v) => formatNumber(v) },
      { text: 'Částka do', dataField: 'amountTo', sort: true, formatter: (v) => formatNumber(v) },
      { text: 'Účel', dataField: 'purposes',
        formatter: (purposes) => purposes.map(({ name }, i) => <span key={i} className="td-value">{name}</span>) },

      { text: 'Fixy', dataField: 'fixes',
        formatter: (fixes) => fixes.map(({ length }, i) => <span key={i} className="td-value">{`${length}R`}</span>) },
      { text: 'Slevy', dataField: 'discounts',
        formatter: (discounts) => discounts.map(({ icon, name, rate, description }, i) =>
          <span key={i} className="td-value">
            <OverlayTrigger placement="auto" overlay={<Popover>
              <Popover.Title>{name} ({rate}%)</Popover.Title>
              <Popover.Content>{description}</Popover.Content>
            </Popover>}>
              <img src={`/img/discounts/${icon}`} width="20" alt={name} title={name} />
            </OverlayTrigger>
          </span>)  },

      { text: 'Platnost', dataField: 'validFrom', sort: true, formatter: (v) => formatDate(v) },
      { text: 'Expirace', dataField: 'expires', sort: true, formatter: (v) => formatDate(v) },

      { text: '', isDummyField: true, dataField: '_actions',
          headerAttrs: { width: 160 },
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ editId: row.id, editDuplicate: false });
              }}>
              <Icon icon='pen' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/duplicate`)} className="btn btn-sm btn-primary mr-3"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ editId: row.id, editDuplicate: true });
              }}>
              <Icon icon='copy' />
            </Link>}
          </div>) }
    ];
  }

  getFilter() {
    return [
      { override: 'mortgageCompany', field: 'product.companyId', placeholder: '= Vyberte producenta =', overrideParams: ['mortgage'] },
      { label: 'Splatnost od', type: 'number', format: 'integer', field: 'maturityFrom' },
      { label: 'Splatnost do', type: 'number', format: 'integer', field: 'maturityTo' },
      { label: 'LTV od', type: 'number', format: 'float', field: 'ltvFrom' },
      { label: 'LTV do', type: 'number', format: 'float', field: 'ltvTo' },
      { label: 'Částka od', type: 'number', format: 'number', field: 'amountFrom' },
      { label: 'Částka do', type: 'number', format: 'number', field: 'amountTo' },
      { label: 'Platnost od', type: 'date', field: 'validFrom[after]' },
      { label: 'Expirace do', type: 'date', field: 'expires[before]' },
    ];
  }

  render() {
    const { entity, apiEntity, history } = this.props;
    const { editId, editDuplicate, isCompanySelected } = this.state;

    return (
      <div>

        <Aside on={!!editId}>
          <Edit aside id={editId} entity={entity} apiEntity={apiEntity} history={history} duplicate={Boolean(editDuplicate)}
            onSave={() => this.onSave()}
            onClose={() => this.setState({ editId: null, editDuplicate: false }) }/>
        </Aside>

        <div className="navbar m-0 pr-2">
          <h1>{this.getTitle()}</h1>
          {this.canDo('create') && <Link to={this.getEndpoint('new')} className="primary-button">+ nový</Link>}
        </div>

        {this.renderFilter()}

        {!isCompanySelected && <div className="text-center mt-5">
          <em>Pro zobrazení sazeb vyberte nejdříve producenta.</em>
        </div>}

        {isCompanySelected && <BootstrapTable
          classes="table-sm"
          bootstrap4 hover remote
          bordered={false}
          keyField="id"
          data={this.getData()}
          columns={this.getColumns()}
          onTableChange={this.onTableChange()}
        />}

        {this.renderPagination()}

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (List)
