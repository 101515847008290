import React from 'react';
import { toast, Slide } from 'react-toastify';
import { uniqueGenerator } from './unique';

const messages = {};
const showCallbacks = [];
const deleteCallbacks = [];
const updateCallbacks = [];

/**
 * Register callback to be triggered when a message is shown
 * @param {function} cb
 */
export const onShowMessage = (cb) => showCallbacks.push(cb);

/**
 * Register callback to be triggered when a message is closed
 * @param {function} cb
 */
export const onDeleteMessage = (cb) => deleteCallbacks.push(cb);

/**
 * Register callback to be triggered on any update in messages list
 * @param {function} cb
 */
export const onUpdateMessages = (cb) => updateCallbacks.push(cb);

/**
 * Show a toast error message
 * @param {string} severity
 * @param {string} message
 * @param {string} title
 * @param {object} options
 */
export const showMessage = (severity, message, title = null, options = {}) => {
  const id = uniqueGenerator();
  messages[id] = true;

  // trigger callback registered for message onShow
  showCallbacks.map((cb) => cb(id, Object.keys(messages)));
  updateCallbacks.map((cb) => cb(Object.keys(messages)));

  const toastOptions = {
    toastId: id,
    type: severity,
    containerId: 'app-messages',
    transition: Slide,
    className: 'app-message',
    onClose: () => {
      delete messages[id];

      // trigger callback registered for message onClose
      deleteCallbacks.map((cb) => cb(id, Object.keys(messages)));
      updateCallbacks.map((cb) => cb(Object.keys(messages)));
    },
    ...options
  };

  return toast(<>
    {title && <span className="title">{title}</span>}
    <span>{message}</span>
  </>, toastOptions);
}

/**
 * Error message
 * @param {string} message
 * @param {string} title
 */
export const showErrorMessage = (message, title) => {
  return showMessage(toast.TYPE.ERROR, message, title, {
    autoClose: false,
  });
}

/**
 * Warning
 * @param {string} message
 * @param {string} title
 */
export const showWarningMessage = (message, title) => {
  return showMessage(toast.TYPE.WARNING, message, title, {
    autoClose: 15000,
  });
}

/**
 * Info message
 * @param {string} message
 * @param {string} title
 */
export const showInfoMessage = (message, title) => {
  return showMessage(toast.TYPE.INFO, message, title);
}

/**
 * Success message
 * @param {string} message
 * @param {string} title
 */
export const showSuccessMessage = (message, title) => {
  return showMessage(toast.TYPE.SUCCESS, message, title);
}

/**
 * Dismiss all currently displayed messages
 */
export const dismissMessages = () => {
  toast.dismiss();
}
