import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect } from 'react-redux';
import _ from 'lodash';

import { getLocalToken } from 'store/user';
import { getRoutes } from 'config/routes';

// import ErrorBoundary from './ErrorBoundary';

import UserContext from 'components/UserContext';
import Header from 'components/Header';
import LoaderOverlay from 'components/LoaderOverlay';

/**
 * Basic Application router with producers’ sub-routing
 * determination.
 */
class App extends React.Component {

  render() {
    const { user } = this.props;

    const roles = user ? _.get(user, 'user.roles') : null;
    const routes = getRoutes(roles);

    return (
      // <ErrorBoundary>
      <Router basename={process.env.PUBLIC_URL}>
        <UserContext>
          <Header />
          <Switch>
            {
              routes.length > 0 && routes.map( ({ path, component }) =>
                <Route key={path} path={path} component={component} />
              )
            }
            <Redirect from="/" to={getLocalToken() ? '/' : '/login'} />
          </Switch>
        </UserContext>
        <LoaderOverlay />
      </Router>
      // </ErrorBoundary>
    );
  }
}

export default connect(
  state => ({
    user: state.user,
  }),
  dispatch => ({}),
) (App)
