import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import Forms from 'components/Forms.jsx';
import LabelValue from 'components/LabelValue.jsx';
import ConfirmDialog from 'components/ConfirmDialog.jsx';

import { crud } from 'store/api';
import { getLoanByType } from 'config/loans';

class Edit extends CrudComponent {

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const id = this.getId();
    if (id) {
      return this.api.find(id)
        .then(( data ) => {
          let editItem;
          if (data) {
            editItem = { ...data };
          }
          this.setState({ editItem });
        });
    }
  }

  getTitle() {
    const { editItem } = this.state;

    return !this.isNew() && editItem ?
      `${getLoanByType('consumerCredit').title} / Účel ${_.get(editItem, 'name')} ` :
      'Nový účel';
  }

  render() {
    const { editItem } = this.state;
    const onChange = field => v => this.changeDetailItem(field, v);
    const get = field => _.get(editItem, field);

    return <div>

      <ConfirmDialog {...this.state.confirm} />

      <div className="navbar justify-content-start">
        <div className="col-auto">
          <Link className="p-4" to={this.getEndpoint()}>
            <Icon icon="chevron-left" />
          </Link>
        </div>
        <h1 className="ml-2">{this.getTitle()}</h1>
      </div>

      <div className="container pt-4">

        <Card body>
          <div className="row">

            <LabelValue className="col-sm-4" label="Název">
              <Forms.Input value={get('name')} onChange={onChange('name')} />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Popis">
              <Forms.Textarea value={get('description')} onChange={onChange('description')} />
            </LabelValue>

            <div className="form-group mb-0 col-sm-12 text-right pt-4">
              <button type="button" className="btn btn-primary font-weight-bold mr-2" onClick={() => this.save()}>
                Uložit
              </button>
              <Link to={this.getEndpoint(this.getId())} className="btn btn-secondary font-weight-bold">
                Zrušit
              </Link>
            </div>
          </div>
        </Card>

        {this.getId() && this.canDo('delete') && <div className="text-center p-3">
          <button className="btn btn-outline-danger p-2" onClick={() => this.delete()}>Smazat</button>
        </div>}

      </div>

    </div>;
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (Edit)
