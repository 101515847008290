/* eslint-disable import/no-anonymous-default-export */

// ACTIONS

export const SET_ACL = 'SET_ACL';

export const setAcl = (payload) => {
  return { type: SET_ACL, payload };
}

// STORE

export default (state = [], action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_ACL]: (_, acl) => {
      return acl;
    },
  };

  return reducer[type] ? reducer[type](state, payload, action) : state;
}
