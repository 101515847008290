/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Me from 'pages/Me';
import Progressions from 'pages/Progressions';
import Users from 'pages/Users';
import Companies from 'pages/Companies';
import Types from 'pages/Types';
import ProductGroups from 'pages/ProductGroups';
import Products from 'pages/Products';
import Structure from 'pages/Structure';
import ProductStructure from 'pages/ProductStructure';

import LoanMortgageRates from 'pages/Loan/Mortgage/Rates';
import LoanMortgagePurposes from 'pages/Loan/Mortgage/Purposes';
import LoanMortgageFixes from 'pages/Loan/Mortgage/Fixes';
import LoanMortgageDiscounts from 'pages/Loan/Mortgage/Discounts';
import LoanAmericanMortgageRates from 'pages/Loan/AmericanMortgage/Rates';
import LoanAmericanMortgagePurposes from 'pages/Loan/AmericanMortgage/Purposes';
import LoanAmericanMortgageFixes from 'pages/Loan/AmericanMortgage/Fixes';
import LoanAmericanMortgageDiscounts from 'pages/Loan/AmericanMortgage/Discounts';
import LoanConsumerCreditRates from 'pages/Loan/ConsumerCredit/Rates';
import LoanConsumerCreditPurposes from 'pages/Loan/ConsumerCredit/Purposes';
import LoanConsumerCreditFixes from 'pages/Loan/ConsumerCredit/Fixes';
import LoanConsumerCreditDiscounts from 'pages/Loan/ConsumerCredit/Discounts';
import LoanBuildingLoanRates from 'pages/Loan/BuildingLoan/Rates';
import LoanBuildingLoanPurposes from 'pages/Loan/BuildingLoan/Purposes';
import LoanBuildingLoanFixes from 'pages/Loan/BuildingLoan/Fixes';
import LoanBuildingLoanDiscounts from 'pages/Loan/BuildingLoan/Discounts';

import { ROLE_USER, ROLE_ADMIN, ROLE_ASSISTANT } from './acl';

export const routes = {

  login: {
    path: '/login',
    component: Login,
  },

  logout: {
    path: '/logout',
    component: Logout,
    roles: [ ROLE_USER ],
  },

  me: {
    path: '/me',
    component: Me,
    roles: [ ROLE_USER ],
  },

  progressions: {
    path: '/progressions',
    component: Progressions,
    roles: [ ROLE_ADMIN ],
  },

  users: {
    path: '/users',
    component: Users,
    roles: [ ROLE_ADMIN ],
  },

  companies: {
    path: '/companies',
    component: Companies,
    roles: [ ROLE_ADMIN ],
  },

  types: {
    path: '/types',
    component: Types,
    roles: [ ROLE_ADMIN ],
  },

  productGroups: {
    path: '/productGroups',
    component: ProductGroups,
    roles: [ ROLE_ADMIN ],
  },

  products: {
    path: '/products',
    component: Products,
    roles: [ ROLE_ADMIN ],
  },

  productStructure: {
    path: '/productStructure',
    component: ProductStructure,
    roles: [ ROLE_ADMIN ],
  },

  feeStructure: {
    path: '/productStructure',
    component: ProductStructure,
    roles: [ ROLE_ASSISTANT ],
  },

  structure: {
    path: '/structure',
    component: Structure,
    roles: [ ROLE_ADMIN ],
  },

  mortgage: {
    path: '/loan/mortgage',
    routes: {
      rates: { path: '/rates', component: LoanMortgageRates, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      purposes: { path: '/purposes', component: LoanMortgagePurposes, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      discounts: { path: '/discounts', component: LoanMortgageDiscounts, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      fixes: { path: '/fixes', component: LoanMortgageFixes, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
    },
  },

  americanMortgage: {
    path: '/loan/americanMortgage',
    routes: {
      rates: { path: '/rates', component: LoanAmericanMortgageRates, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      purposes: { path: '/purposes', component: LoanAmericanMortgagePurposes, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      discounts: { path: '/discounts', component: LoanAmericanMortgageDiscounts, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      fixes: { path: '/fixes', component: LoanAmericanMortgageFixes, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
    },
  },

  consumerCredit: {
    path: '/loan/consumerCredit',
    routes: {
      rates: { path: '/rates', component: LoanConsumerCreditRates, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      purposes: { path: '/purposes', component: LoanConsumerCreditPurposes, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      discounts: { path: '/discounts', component: LoanConsumerCreditDiscounts, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      fixes: { path: '/fixes', component: LoanConsumerCreditFixes, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
    },
  },

  buildingLoan: {
    path: '/loan/buildingLoan',
    routes: {
      rates: { path: '/rates', component: LoanBuildingLoanRates, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      purposes: { path: '/purposes', component: LoanBuildingLoanPurposes, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      discounts: { path: '/discounts', component: LoanBuildingLoanDiscounts, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
      fixes: { path: '/fixes', component: LoanBuildingLoanFixes, roles: [ ROLE_ADMIN, ROLE_ASSISTANT ] },
    },
  },

};


export const isProtected = (path) => {
  if (path && (path === '/login' || path.match(/^\/me\/recover-password/))) {
    return false;
  }
  return true;
}

/**
 * Get all routes with absolute paths that are accessible for defined
 * permission roles. All routes are recursively flattened.
 * @param {object} routes set of routes to be parsed recursively
 * @param {array} roles roles to which apply permissions or empty array
 * @param {string} path nested path of recursive routes
 */
const getParsedRoutes = (routes, roles = [], path = '') => {
  return _.flatMap(routes, r => {
    if (!roles || !r.roles || (roles && r.roles && _.intersection(roles, r.roles).length > 0)) {
      let route = {...r};
      route.path = `${path}${route.path}`;
      if (r.routes) {
        return getParsedRoutes(r.routes, roles, route.path);
      }
      return [route];
    }
    return null;
  }).filter(v => v);
}

/**
 * Get all routes that the specified roles are able to access.
 * @param {array} roles
 */
export const getRoutes = (roles = []) => getParsedRoutes(routes, roles);

/**
 * Get one route by name and absolute path, if nested.
 * @param {string} name route name
 */
export const getRoute = (name) => {
  let route = routes;
  let path = '';
  const segments = name.split('.');
  for (let segment of segments) {
    route = {..._.get(route, segment)};
    if (route) {
      path += route.path;
      if (!route.routes) {
        route.path = path;
        return route;
      }
      if (route.routes) {
        route = route.routes;
        continue;
      }
    }
  }
  return null;
}

/**
 * Check if specified user role can access the route.
 * @param {string} role user role
 * @param {object} route
 */
export const canAccess = (role, route) => {
  const { roles } = route;
  if (roles && _.indexOf(roles, role) > -1) {
    return true;
  }
  return false;
}


export default routes;
