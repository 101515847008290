import React, { useState } from 'react';
import _ from 'lodash';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import Icon from 'components/Icon';
import Tariff from './Tariff';
import { classes } from 'services/utils';

const Type = ({ index, path, type, tariffs, onEdit }) => {

  const [ isOpen, setIsOpen ] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return <div>
    <label>
      <span className="structure-item-name" onClick={toggle}>
        <span className="structure-item-control">
          {isOpen ? <Icon icon="minus-square" /> : <Icon icon="plus-square" />}
      </span>
      {_.get(type, 'name')}
      </span>
    </label>

    {isOpen && <Droppable type="type" droppableId={`type/${index}-${type.id}/${path}.tariffs`}>
      {(provided) => (
        <div ref={provided.innerRef}>
          {tariffs.map(( tariff, index ) =>
            <Draggable
              key={tariff.id}
              draggableId={`tariff/${tariff.id}`}
              index={index}>

              {(provided, snapshot ) => <div
                  className={classes({'structure-item': true, 'structure-item--dragging': snapshot.isDragging })}
                  ref={provided.innerRef} {...provided.draggableProps}>
                <div className="structure-drag-handle" {...provided.dragHandleProps}></div>
                <Tariff index={index} path={`${path}.tariffs.${index}`} tariff={tariff} onEdit={onEdit} />
              </div>}

            </Draggable> )}
            {provided.placeholder}
            <div className="structure-add-button">
              <button className="btn btn-link" onClick={() => onEdit(null, 'tariff', `${path}.tariffs.${index}`, { typeId: type.id, sort: tariffs.length })}>+ tarif</button>
            </div>
        </div>
      )}
    </Droppable>}

  </div>

}

export default Type;
