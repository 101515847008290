/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import { canDo } from 'services/auth';

export default ({ entity, apiEntity, listComponent, editComponent, newComponent, detailComponent, children }) => {
  const List = listComponent;
  const Edit = editComponent;
  const New = newComponent;
  const Detail = detailComponent;

  return (
    <Router basename={`/${entity}`}>
      <Switch>

        {editComponent && canDo(`${entity}.update`) &&
          <Route exact path="/:id/edit" render={props => <Edit {...props} entity={entity} apiEntity={apiEntity} />}/>
        }

        {newComponent && canDo(`${entity}.create`) &&
          <Route exact path="/new" render={props => <New {...props} entity={entity} apiEntity={apiEntity} />}  />
        }

        {detailComponent && canDo(`${entity}.read`) &&
          <Route exact path="/:id(\d+)" render={props => <Detail {...props} entity={entity} apiEntity={apiEntity} />} />
        }

        {listComponent && canDo(`${entity}.read`) &&
          <Route exact path="/" render={props => <List {...props} entity={entity} apiEntity={apiEntity} />} />
        }

        {children}

        <Redirect to="/" />

      </Switch>
    </Router>
  );
}
