import React from 'react';
import _ from 'lodash';

import EditPanel from './EditPanel';
import Icon from 'components/Icon';
import { classes } from 'services/utils';

const Parameter = ({ parameter, path, onEdit }) => {

  return <div>
    <label className={classes({ 'structure-item--hidden': !parameter.isVisible })}>
      {_.get(parameter, 'parameter.name')}
      <Icon className="structure-item-icon-hidden" icon="eye-slash" />
      <EditPanel type="parameter" id={parameter.id} path={path} onEdit={onEdit} />
    </label>
  </div>

}

export default Parameter;
