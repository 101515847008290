import React from 'react';
import _ from 'lodash';
import { Button, Card } from 'react-bootstrap';

import Form from 'components/Forms.jsx';
import { classes } from 'services/utils';
import { ARCHIVE, HIDDEN, VISIBLE } from 'config/products';
import Icon from 'components/Icon';

class ProductsSelector extends React.Component {

  constructor(props) {
    super(props);

    const { products, selectedProducts } = props;

    this.state = {
      products,
      selectedProducts,
    }

    this.onEsc = this.onEsc.bind(this);
  }

  onEsc({ keyCode }) {
    if (keyCode === 27) { // escape
      this.close();
    }
  }

  selectProduct(isSelected, productId) {
    return this.props.onSelectProduct(isSelected, productId);
  }

  close() {
    return this.props.onClose();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEsc, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEsc, false);
  }

  render() {
    return <>
      <div className="products-popover-overlay" onClick={() => this.close()}></div>
      <Card className="products-popover">

        <Card.Header>
          <h3>Produkty</h3>
        </Card.Header>

        <Card.Body>
          {_.map(this.state.products, ({ company, products}) =>
            <div className="products-popover-company" key={company.id}>
              <h4>{company.name}</h4>
              <div className="products-popover-list">
                {products.map(product => <span key={product.id}
                  className={classes({
                    'products-popover-product form-group': true,
                    'products-popover-product--archive': product.status !== VISIBLE,
                    'products-popover-product--selected': Boolean(_.get(this.state, `selectedProducts.${product.id}`)),
                  })}>
                    <Form.Checkbox label={<>
                     {product.name}
                     <span className="pl-1">
                       {product.status === HIDDEN && <Icon icon="eye-slash" />}
                       {product.status === ARCHIVE && <Icon icon="archive" />}
                     </span>
                     </>}
                      value={Boolean(_.get(this.state, `selectedProducts.${product.id}`))}
                      onChange={(v) => this.selectProduct(v, product.id)} />
                  </span>)}
              </div>
            </div>
          )}
        </Card.Body>

        <Card.Footer>
          <div className="products-popover-footer text-right">
            <Button variant="primary" onClick={() => this.close()}>OK</Button>
          </div>
        </Card.Footer>

      </Card>
    </>
  }

}

export default ProductsSelector;
