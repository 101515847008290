
/**
 * Return the unique ID generator. Call `const generator = uniqueId()` to create
 * a generator and then `let value = generator()` to generate a unique ID.
 * Can be called immediatelly in a row and will always generate a new ID.
 *
 * Source: https://dev.to/pranay_rauthu/uuid-in-javascript-obc
 */
export const uniqueId = () => {
  const firstItem = {
    value: '0'
  };

  let counter = '123456789'.split('').reduce((prev, value) => ({ value, prev }), firstItem);
  firstItem.prev = counter;

  return () => {
    let now = Date.now();
    if (typeof performance === 'object' && typeof performance.now === 'function') {
      now = performance.now().toString().replace('.', '');
    }
    counter = counter.prev;

    return `${now}${Math.random().toString(16).substr(2)}${counter.value}`;
  }
}

export const uniqueGenerator = uniqueId();
