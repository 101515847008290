/* eslint-disable import/no-anonymous-default-export */

const config = {

  types: {
    mortgage: {
      title: 'Hypotéky',
      type: 6,
    },
    americanMortgage: {
      title: 'Americké hypotéky',
      // TODO - doplnit typ ameriky
      type: 0,
    },
    consumerCredit: {
      title: 'Spotřebitelské úvěry',
      type: 8,
    },
    buildingLoan: {
      title: 'Stavební spoření',
      type: 7,
    },
  },

  discounts: {
    types: {
      INSURANCE: 'pojištění',
      DEAL: 'sleva',
      DOMICILE: 'domicil',
    },
  },

  fixes: {
    types: {
      FIX: 'fixní',
      VAR: 'variabilní',
    },
  },

}

export default config;

// export const getLoanTypeId = loanType => config.types[loanType].type;

export const getLoanByType = loanType => config.types[loanType];
