
import _ from 'lodash';
import store from 'store';
import { setAcl } from 'store/acl';
import { getConfig } from 'config';

const r = (rule) => _.toLower(rule) === 'allow';

/**
 * Initialize ACL rules for the current user.
 */
export const initAcl = () => {
  const { user } = store.getState();
  const roles = _.get(user, 'user.roles');

  const acl = {};

  const set = (action, rule) => {
    if (!acl[action] || (!r(acl[action]) && r(rule))) {
      acl[action] = rule;
    }
  }

  if (roles) {
    for (let role of roles) {
      const actions = getConfig(`acl.${role}`);

      _.map(actions, (action, rule) => {
        if (typeof action === 'string') {
          set(action, rule);
        } else if (action instanceof Array) {
          action.map(a => set(a, rule));
        }
      });
    }
  }

  return store.dispatch(setAcl(acl));
}


/**
 * Check if the current user is allowed to perform
 * the given action. If no such action is defined in
 * ACL rules, then the "all"-rule is applied.
 * @param {string} action
 * @return {boolean}
 */
export const canDo = (action) => {
  const { acl } = store.getState();
  const rule = _.get(acl, action);
  const all = _.get(acl, 'all');

  if (!_.isNil(rule)) {
    return r(rule);
  }

  return r(all);
}
