/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import Forms from 'components/Forms.jsx';
import LabelValue from 'components/LabelValue.jsx';
import ConfirmDialog from 'components/ConfirmDialog.jsx';

import { crud } from 'store/api';
import { getConfig } from 'config';

class EditParameter extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      newParameter: false,
    };

  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const id = this.getId();

    if (id) {
      this.showLoading();
      return this.api.find(id)
        .then(( data ) => {
          let editItem;
          if (data) {
            editItem = { ...data };
          }
          this.setState({ editItem });
        })
        .finally(() => this.hideLoading());

    } else {
      // new item
      const { params } = this.props;
      params && this.setState({
        editItem: params,
      });
    }
  }

  getId() {
    return _.get(this.props, 'id', null) || null;
  }

  getTitle() {
    return 'Produktový parametr';
  }

  prepareItemForSave(item) {
    return {
      ...item,
      tariffCategoryParameterId: item.tariffCategoryParameterId ? parseInt(item.tariffCategoryParameterId) : null,
      productId: item.productId ? parseInt(item.productId) : null,
    };
  }

  render() {
    const { editItem } = this.state;
    const onChange = field => v => this.changeDetailItem(field, v);
    const get = field => _.get(editItem, field);

    return <div>

      <ConfirmDialog {...this.state.confirm} />

      <div className="navbar justify-content-start">
        <div className="col-auto">
          <Link className="p-4" to={this.getEndpoint()} onClick={() => {
            if (this.props.aside && this.props.onClose) {
              this.props.onClose();
              return false;
            }
          }}>
            <Icon icon={ this.props.aside ? 'times' : 'chevron-left' } />
          </Link>
        </div>
        <h1 className="ml-2">{this.getTitle()}</h1>
      </div>

      <div className="container pt-4">

        <Card body>
          <div className="row">

            <LabelValue className="col-sm-6" label="Status">
              <Forms.Select value={get('status')}
                placeholder="= Vyberte ="
                items={getConfig('products.structure.parameter.states')}
                onChange={onChange('status')}
              />
            </LabelValue>

            <LabelValue className="col-sm-6" label="Zobrazit">
              <Forms.Select value={get('display')}
                placeholder="= Vyberte ="
                items={getConfig('products.structure.parameter.displays')}
                onChange={onChange('display')}
              />
            </LabelValue>

            <LabelValue className="col-sm-12" label="Hodnota">
              <Forms.Textarea rows={1} value={get('value')} onChange={onChange('value')} />
            </LabelValue>

            <LabelValue className="col-sm-12" label="Způsob sjednání">
              <Forms.Textarea rows={1} value={get('howto_desc')} onChange={onChange('howto_desc')} />
            </LabelValue>

            <LabelValue className="col-sm-12" label="Limit plnění">
              <Forms.Textarea rows={2} value={get('limit_desc')} onChange={onChange('limit_desc')} />
            </LabelValue>

            <LabelValue className="col-sm-12" label="Plnění v ceně">
              <Forms.Textarea rows={1} value={get('other_desc')} onChange={onChange('other_desc')} />
            </LabelValue>

            <LabelValue className="col-sm-12" label="Podrobnosti">
              <Forms.Textarea rows={9} value={get('deal_means_desc')} onChange={onChange('deal_means_desc')} />
            </LabelValue>

            <LabelValue className="col-sm-12" label="Poznámka">
              <Forms.Textarea rows={1} value={get('comment')} onChange={onChange('comment')} />
            </LabelValue>

            <div className="form-group mb-0 col-sm-12 text-left pt-4">
              <button type="button" className="btn btn-primary font-weight-bold mr-2" onClick={() => this.save()}>
                Uložit
              </button>
              <Link to={this.getEndpoint(this.getId())} className="btn btn-secondary font-weight-bold" onClick={(e) => {
                if (this.props.aside && this.props.onClose) {
                  e.preventDefault();
                  this.props.onClose();
                }
              }}>
                Zrušit
              </Link>
            </div>
          </div>
        </Card>

      </div>

    </div>;
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
    crudCache: (entity) => dispatch(crud(entity, { cache: true })),
  })
) (EditParameter)
