/* eslint-disable import/no-anonymous-default-export */

export const
  YES = 'YES',
  NO = 'NO',
  YES_L = 'YES_L',
  VALUE = 'VALUE',
  LIMITED = 'LIMITED',
  LIMITED_L = 'LIMITED_L';

export const
  ARCHIVE = 'ARCHIVE',
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN';

export const
  OK = 'OK',
  NEW = 'NEW',
  WAITING = 'WAITING';

export default {

  types: {
    [ARCHIVE]: 'archivován',
    [VISIBLE]: 'aktivní',
    [HIDDEN]: 'skrytý',
  },

  structure: {
    parameter: {
      values: {
        [YES]: 'ano',
        [NO]: 'ne',
        [LIMITED]: 'omezeně',
        [YES_L]: 'ano (L)',
        [LIMITED_L]: 'omezeně (L)',
        default: 'omezeně',
      },

      states: [OK, NEW, WAITING].map(v => ({ key: v, value: v })),

      displays: [VALUE, YES, NO, LIMITED, YES_L, LIMITED_L].map(v => ({ key: v, value: v })),
    }
  }

}
