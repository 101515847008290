import React from 'react';
import _ from 'lodash';

import CrudFilter from 'components/CrudFilter';
import Paginator from 'components/Paginator.jsx';

import ArrayPaginator from 'utils/ArrayPaginator';
import { showSuccessMessage } from 'services/messages';
import { showLoading, hideLoading } from 'store/loading';
import store from 'store';
import { canDo } from 'services/auth';

class CrudComponent extends React.Component {

  constructor(props = {}) {
    super(props);

    const { loading, entity, apiEntity } = props;

    this.state = {
      loading: loading || false,
      confirm: {
        show: false,
      },

      /* codebooks */
      companies: [],
      companiesItems: [],
      products: [],
      productsItems: [],

      /* data */
      data: [],
      total: null,

      pagination: null,
      page: null,
      filter: {},
      defaultFilter: {},
      sort: null,

      editItem: {},
      item: null,
      backupItem: null,
      isEdit: false,
    };

    // this.api = api;
    this.api = null;
    this.initEntities(entity, apiEntity);

    this.history = props.history;;
  }

  /**
   * Show loading overlay
   * @returns Promise
   */
  showLoading() {
    return store.dispatch(showLoading());
  }

  /**
   * Hide loading overlay
   * @returns Promise
   */
  hideLoading() {
    return store.dispatch(hideLoading());
  }

  /**
   * Initialize CRUD entities – which entity we are
   * working with. Here, entity is the name of current
   * CRUD (FE) endpoint and API (BE) endpoint. If the BE
   * endpoint is different, then we define apiEntity too
   * @param {string} entity
   * @param {string} apiEntity - optional entity name if differs
   */
  initEntities(entity, apiEntity = null) {
    const { crud } = this.props;
    if (crud) {
      this.api = apiEntity ? crud(apiEntity) : crud(entity);
    }
    this.endpoint = `/${entity}`;
  }

  /**
   * Check if the location has changed in order to refresh
   * the pagination of the CRUD list
   * @param {object} prevProps
   */
  checkUpdatePage(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;
    const newPage = this.getPageFromUrl() || 1;
    const page = parseInt(this.state.page) || 1;

    if (locationChanged && !Number.isNaN(newPage) && newPage !== page) {
      // setPage will do the rest
      this.setPage(newPage);
    }
  }

  /**
   * Extracts the parameter from the query params of the URL
   * @param {string} param
   * @param {string} defaultValue
   * @returns
   */
  getParamFromUrl(param, defaultValue = null) {
    const { location } = this.props;
    const params = location ? new URLSearchParams(location.search) : null;
    return params && params.get(param) ? parseInt(params.get(param)) : defaultValue;
  }

  /**
   * Extract the page number from the URL query params
   * @returns page number
   */
  getPageFromUrl() {
    return this.getParamFromUrl('page');
  }

  /**
   * Prepares the URL query params for the next API request
   * from the current component state. The parameters contain
   * page number, filters and sort.
   * @param {object} params
   * @returns URL query parameters
   */
  getUrlParams(params = {}) {
    const { page, filter, sort } = this.state;
    if (page) {
      params['page'] = page;
    }
    if (filter) {
      params = {...params, ...filter};
    }
    if (sort) {
      params = {...params, ...sort};
    }
    return params;
  }

  /**
   * Composes the query string for the next API request
   * @returns query string part of the URI
   */
  getCurrentQueryString() {
    let params = this.getUrlParams();
    const qs = new URLSearchParams(params)
    return `?${qs.toString()}`;
  }

  /**
   * Re-composes the URL according to current
   * component state and the need for current
   * query string (to match the state)
   */
  updateCurrentUrl() {
    const { history, location } = this.props;
    return history.push({
      pathname: location.pathname,
      search: this.getCurrentQueryString(),
    });
  }

  /**
   * Retrieves the current page number, either
   * from the state or from the query string
   * @returns page number
   */
  getPage() {
    return this.state.page || this.getPageFromUrl();
  }

  /**
   *
   * @param {string} path path segment
   * @returns absolute path
   */
  getEndpoint(path = '') {
    // return `${this.endpoint}/${path}`;
    return `/${path}`;
  }

  /**
   * Redirects to defined CRUD endpoint with history.push
   * @param {string} path
   */
  redirect(path = '') {
    const url = this.getEndpoint(path);
    this.history.push(url);
  }

  /**
   * Retrieves the current entity’s ID from the URL
   * or the component props
   * @returns ID value
   */
  getId() {
    const { id } = _.get(this.props, 'match.params');
    if (!id) {
      return _.get(this.props, 'id', null) || null;
    }
    return id;
  }

  /**
   * Toggle show/hide loading spinner overlay
   */
  toggleLoading() {
    const { loading } = store.getState();
    store.dispatch(loading ? hideLoading() : showLoading());
  }

  /**
   * Checks if the given value is neither null nor undefined
   * @param {any} value
   * @returns {boolean}
   */
  notEmpty(value) {
    return value !== null && value !== undefined;
  }

  /**
   * Promise wrapper for the setState function where `path`
   * is the path to the value in the state, e.g. 'foo.bar'
   * resolves to `state: { foo: { bar: value } }`
   * @param {string} path to the value
   * @param {any} value
   * @returns {Promise}
   */
  handleStateChange(path, value) {
    return new Promise((resolve) => this.setState((state) => _.set(state, path, value), () => resolve()));
  }

  /**
   *
   * @param {string} title
   * @param {string} confirmColor
   * @param {string} confirmLabel
   * @param {string} cancelLabel
   * @returns {Promise}
   */
  confirm(title, confirmColor, confirmLabel, cancelLabel) {
    return new Promise((resolve, reject) => {
      const onYes = () => this.setState({ confirm: { show: false } }, resolve);
      const onNo = () => this.setState({ confirm: { show: false } }, reject);
      this.setState({ confirm: { show: true, title, confirmColor, confirmLabel, cancelLabel, onYes, onNo }});
    })
  }

  /**
   * Set the codebook items to current state
   * @param {string} codebook name
   * @param {function} mapFn map function to transform data into key-values
   * @returns {Promise}
   */
  setCodebookData(codebook, mapFn = null) {
    return (data) => {
      const items = data
        .map(mapFn || (({ id, name }) => ({ key: id, value: name })))
        .filter(({ value }) => value !== '');
      return this.handleStateChange(codebook, items);
    }
  }

  /**
   * Checks if the user can perform the particular (sub)action,
   * e.g. 'edit', 'create', 'read', ...
   * @param {string} subaction
   * @returns {boolean}
   */
  canDo(subaction) {
    return canDo(`${this.props.entity}.${subaction}`);
  }

  /******* LIST ITEMS *******/

  /**
   * Data setter for the CRUD list view. The `data` can be also
   * the ArrayPaginator instance which is a helper/wrapper for
   * easier pagination (contains additional pagination data)
   * @param {any} data
   * @returns {Promise}
   */
  setData(data) {
    let total = null;
    if (data) {
      if (data instanceof ArrayPaginator) {
        total = data.getCount();
      }
    }
    return new Promise((resolve) => this.setState({ data, total }, () => resolve()));
  }

  /**
   * CRUD list data getter
   * @returns {array}
   */
  getData() {
    const { data } = this.state;
    if (!data) {
      return [];
    }

    if (data instanceof ArrayPaginator) {
      const items = [];
      for (let item of data) {
        items.push(item);
      }
      return items;
    }

    return data;
  }

  /**
   * Returns the formatted value for data total count,
   * e.g. ' (28)' or nothing if the total state is
   * not available
   * @returns {string}
   */
  getTotalFormatted() {
    const { total } = this.state;
    return total ? ` (${total})` : '';
  }

  /**
   * Sets the current page number for CRUD listing view,
   * updates the URL and reloads data
   * @param {number} page current page number
   * @returns {Promise}
   */
  setPage(page) {
    return this.handleStateChange('page', page)
      .then(() => this.updateCurrentUrl())
      .then(() => this.loadData());
  }

  /**
   * Sets the current filter values for CRUD listing view,
   * resets the pagination to first page, updates the URL
   * and reloads data
   * @param {object} filter filter data
   * @returns {Promise}
   */
   setFilter(filter) {
    return this.handleStateChange('filter', filter)
      .then(() => this.handleStateChange('page', 1))
      .then(() => this.updateCurrentUrl())
      .then(() => this.loadData());
  }

  /**
   * Sets the sort for current data set in the CRUD list
   * view, resets the pagination to first page, updates
   * the URL and reloads data
   * @param {string} name field name
   * @param {string} order order direction (asc, desc)
   * @returns {Promise}
   */
  setSort(name, order) {
    return this.handleStateChange('sort', { [`order[${name}]`]: order })
      .then(() => this.handleStateChange('page', 1))
      .then(() => this.updateCurrentUrl())
      .then(() => this.loadData());
  }

  /**
   * Listener to handle React Bootstrap Table onChange event.
   * Listens to `sort` change
   * @returns {function}
   */
  onTableChange() {
    return (type, state) => {
      if (type === 'sort') {
        const { sortField, sortOrder } = state;
        this.setSort(sortField, sortOrder);
      }
    }
  }

  /**
   * Returns the pagination data to display paginator component
   * @returns {object}
   */
  getPagination() {
    const { data } = this.state;
    if (data && data instanceof ArrayPaginator) {
      return data.getPagination();
    }
    return null;
  }

  /**
   * Renders `Paginator` component
   * @returns {Paginator}
   */
  renderPagination() {
    const pagination = this.getPagination();
    return pagination && <Paginator
      {...pagination}
      onClick={(page) => this.setPage(page)} />;
  }

  /**
   * Renders `CrudFilter` component
   * @returns {CrudFilter}
   */
  renderFilter() {
    const config = this.getFilter && this.getFilter();
    const { filter, defaultFilter } = this.state;
    return config && <CrudFilter
      config={config}
      filter={filter}
      defaultFilter={defaultFilter}
      onChange={(filter) => this.setFilter(filter)} />
  }


  /******* EDIT ITEM *******/

  /**
   * Checks whether the currently edited record
   * is new or not
   * @returns {boolean}
   */
  isNew() {
    return !Boolean(this.getId());
  }

  /**
   * Generic handler for item creation. By default
   * the method redirect the view back to CRUD list
   * @param {object} data
   */
  handleSuccessfulCreate({ id }) {
    if (id) {
      return this.redirect();
    }
  }

  /**
   * Change a value in `state.editItem`. The `path` is
   * a complete path to shallow or nested attribute,
   * e.g. path `foo.bar` refers to `state.editItem.foo.bar`
   * @param {string} path path to item attribute
   * @param {any} value
   * @param {function} cb callback to be called after state is sete
   * @returns {void}
   */
  changeDetailItem(path, value, cb = null) {
    const { editItem } = this.state;
    _.set(editItem, path, value);
    return this.setState({ editItem }, cb);
  }

  /**
   * Saves the current item via CRUD REST API. The `prepareItemForSave`
   * method will be called if available and will use the returned data.
   * If the item already exists and `props.duplicate` is `true`
   * then a new item is created (= duplicated) with the given
   * data. If the edit form is displayed in aside, after successful
   * API save response the `props.onSave` callback is called
   * with a new item ID value
   */
  save() {
    const { editItem } = this.state;
    const item = this.prepareItemForSave ? this.prepareItemForSave(editItem) : {...editItem};

    this.showLoading();
    if (this.getId() && !this.props.duplicate) {
      this.api.update(this.getId(), item)
        .then(() => showSuccessMessage('Uloženo') && this.loadData())
        .then(() => this.props.aside && this.props.onSave && this.props.onSave(this.getId()))
        .finally(() => this.hideLoading());

    } else {
      this.api.create(item)
        .then(this.handleSuccessfulCreate.bind(this))
        .then((data) => showSuccessMessage('Vytvořeno'))
        .then(() => this.props.aside && this.props.onSave && this.props.onSave())
        .finally(() => this.hideLoading());
    }
  }

  /**
   * Shows the confirmation dialog and eventually calls the REST
   * API delete method. If the edit was shown in aside, `props.onSave`
   * method is called, otherwise the user is redirected to CRUD list
   * @param {string} title confirm dialog title
   */
  delete(title = 'Opravdu smazat?') {
    this.canDo('delete') && this.confirm(title, 'danger', 'Smazat')
      .then(() => this.api.delete(this.getId()))
      .then(() => {
        if (this.props.aside && this.props.onSave) {
          this.props.onSave(this.getId());
        } else {
          this.redirect();
        }
      })
      .catch(() => {});
  }

}

CrudComponent.defaultProps = {
  duplicate: false,

  /* ASIDE PROPS */
  aside: false,
  onSave: null,
  onCancel: null,

};

export default CrudComponent;
