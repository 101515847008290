import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import _ from 'lodash';
import Icon from './Icon';
import Nl2Br from './Nl2Br';

import { getConfig } from 'config';
import { YES, NO, YES_L, VALUE, NEW, WAITING } from 'config/products';

class ParameterValue extends React.PureComponent {


  constructor(props) {
    super(props);

    this.values = getConfig('products.structure.parameter.values');
  }

  render() {
    const { parameter } = this.props;

    if (!parameter) {
      return '';
    }

    const { display, status, value, howto_desc, limit_desc, other_desc, deal_means_desc, comment } = parameter;

    let showTooltip = false;
    let tooltipValue = value || '';
    let classes = [];
    let label = value;
    // let wide = false;

    if (display) {
      switch (display) {
        case YES:
        case YES_L:
        case value === 'ano':
          classes.push('success');
          showTooltip = display === YES_L;
          label = this.values[display];
          break;

        case NO:
        case value === 'ne':
          classes.push('danger');
          label = this.values[display];
          break;

        case VALUE:
          break;

        default:
          label = this.values.default;
          classes.push('warning');
          showTooltip = true;
          break;
      }

      if (howto_desc || limit_desc || other_desc || deal_means_desc) {
        showTooltip = true;
        // wide = true;
      }

      if (status === NEW) {
        classes.push('product-structure-parameter--new');
      }

      if (status === WAITING) {
        classes.push('product-structure-parameter--waiting');
      }

      // if (tooltipValue.length > 300) {
      //   wide = true;
      // }
    }

    const popover = showTooltip && (
      <Popover className="product-structure-parameter-popover" placement="auto">
        <Popover.Title>
          {_.upperFirst(_.get(parameter, 'tariffCategoryParameter.parameter.name'))}
        </Popover.Title>
        <Popover.Content>
          <p><Nl2Br>{tooltipValue}</Nl2Br></p>

          {howto_desc && <div>
            <h3>Způsob sjednání</h3>
            <p>{howto_desc}</p>
          </div>}

          {limit_desc && <div>
            <h3>Limit plnění</h3>
            <p>{limit_desc}</p>
          </div>
         }
          {other_desc && <div>
            <h3>Plnění v ceně</h3>
            <p>{other_desc}</p>
          </div>}

          {deal_means_desc && <div>
            <h3>Podrobnosti</h3>
            <p>{deal_means_desc}</p>
          </div>}

          {comment && <div>
            <h3>Poznámka</h3>
            <p>{comment}</p>
          </div>}

        </Popover.Content>
      </Popover>
    );

    const content = (
      <span className={`product-structure-parameter ${classes.join(' ')}`}>
        {label}
        {showTooltip && <Icon className="icon" icon="info-circle" />}
      </span>
    );

    return (
      showTooltip ? <OverlayTrigger placement="auto" overlay={popover}>{content}</OverlayTrigger> : content
    );

  }

}

export default ParameterValue;
