/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getConfig } from 'config';
import { getRoute, canAccess, isProtected } from 'config/routes';
import Icon from './Icon';

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showUserMenu: false,
    };
  }

  isProtected() {
    return isProtected(this.props.location.pathname);
  }

  toggleUserMenu() {
    const { showUserMenu } = this.state;
    this.setState({ showUserMenu: !showUserMenu });
  }

  getItemRoute(item) {
    return item.route ? getRoute(item.route) : null;
  }

  isPath(item) {
    const { location } = this.props;
    const route = this.getItemRoute(item);
    if (route && route.path && location.pathname) {
      return location.pathname.match(new RegExp(`^${route.path}[^a-z0-9-/]?$`, 'i'));
    }
    return false;
  }

  canAccess(route) {
    const { user } = this.props;
    if (!user) {
      return false;
    }

    const roles = _.get(user, 'user.roles') || [];
    for (let role of roles) {
      if (canAccess(role, route)) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { showUserMenu } = this.state;
    const { user } = this.props;
    const thumbnailUrl = _.get(user, 'gravatarUrl');

    return (
      <div className="header">
        <div className="logo">
          <img src="/img/logo-pp.png" width="64" alt="Pojistné Podmínky" />
          <span>Pojistné-Podmínky.cz</span>
        </div>
        {this.isProtected() && <div className="menu">
          <ul>
            {getConfig('menu').map((item) => {
              const itemRoute = this.getItemRoute(item);
              const canAccess = itemRoute ? this.canAccess(itemRoute) : true;

              // 2nd level menu
              const subItems = item.items ? item.items.map((subItem) => {
                const subitemRoute = this.getItemRoute(subItem);
                const canAccessSub = subitemRoute ? this.canAccess(subitemRoute) : true;

                // 3rd level menu
                const subSubItems = subItem.items ? subItem.items.map((subSubItem) => {
                  const subSubitemRoute = this.getItemRoute(subSubItem);
                  const canAccessSub = subSubitemRoute ? this.canAccess(subSubitemRoute) : true;
                  return canAccessSub && <li key={subSubItem.path || subSubItem.title} className={`dropdown-item ${this.isPath(subSubItem) ? 'selected' : ''}`}>
                    <Link className="menu-item" to={subSubitemRoute.path}>{subSubItem.title}</Link>
                  </li>
                }).filter(v => v) : [];

                return canAccessSub && <li key={subItem.path || subItem.title} className={`dropdown-item ${this.isPath(subItem) ? 'selected' : ''}`}>
                  {subItem.items && subSubItems.length > 0 && <>
                    <span className="menu-item">{subItem.title} <Icon className="ml-2" icon="caret-right" /></span>
                    <ul className="dropdown-menu">
                      {subSubItems}
                    </ul>
                  </>}
                  {!subItem.items && <Link className="menu-item" to={subitemRoute.path}>{subItem.title}</Link>}
                </li>
              }).filter(v => v) : [];

              // 1st level menu
              return canAccess && <li key={item.path || item.title} className={this.isPath(item) ? 'selected' : ''}>
                {item.items && subItems.length > 0 && <>
                  <span className="menu-item">{item.title} <Icon className="ml-2" icon="caret-down" /></span>
                  <ul className="dropdown-menu">
                    {subItems}
                  </ul>
                </>}
                {!item.items && <Link className="menu-item" to={itemRoute.path}>{item.title}</Link>}
              </li>
            })}
          </ul>
        </div>}
        {this.isProtected() && <div className="user-menu">
          <ul>
            <li className={ `user-avatar ${showUserMenu && 'active'}` } onClick={() => this.toggleUserMenu()}>
              <a href="#">
                {thumbnailUrl && <img src={`${thumbnailUrl}?s=100`} width="50" alt="" />}
                {!thumbnailUrl && <i className="fas fa-user-tie" />}
              </a>
              <ul className="sub-menu">
                {user && <li><strong>
                  { _.get(user, 'user.name') }
                  { user.partner && ` [${user.partner.partner}]` }
                  { user.isAdmin && ' ADMIN' }
                </strong></li>}
                <li><a href="/logout">Odhlásit</a></li>
              </ul>
            </li>
          </ul>
        </div>}
      </div>
    );
  }
}

export default connect(
  state => ({
    user: state.user,
  })
) (withRouter(Header))
