import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
import { getLoanByType } from 'config/loans';

class List extends CrudComponent {


  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(props) {
    super.checkUpdatePage(props);
  }

  loadData() {
    this.showLoading();
    return this.api.get()
      .then((data) => this.setData(data))
      .finally(() => this.hideLoading());
  }

  getTitle() {
    return `${getLoanByType('mortgage').title} / účely`;
  }

  getColumns() {
    return [
      { text: 'ID', dataField: 'id' },
      { text: 'Název', dataField: 'name' },
      { text: 'Popis', dataField: 'description' },
      { text: '', isDummyField: true, dataField: '_actions',
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3">
              <Icon icon='pen' />
            </Link>}
          </div>) }
    ];
  }

  render() {
    return (
      <div>
        <div className="navbar m-0 pr-2">
          <h1>{this.getTitle()}</h1>
          {this.canDo('create') && <Link to={this.getEndpoint('new')} className="primary-button">+ nový</Link>}
        </div>

        <BootstrapTable
          classes="table-sm"
          bootstrap4 hover remote
          bordered={false}
          keyField="id"
          data={this.getData()}
          columns={this.getColumns()}
          onTableChange={this.onTableChange()}
        />

        {this.renderPagination()}

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (List)
