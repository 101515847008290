import React, { useState } from 'react';
import _ from 'lodash';

import Icon from 'components/Icon';
import Category from './Category';

const Tariff = ({ products, productsData, path, tariff, onEdit }) => {

  const [ isOpen, setIsOpen ] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return <>
    <tr>
      <th className="pl-1" onClick={toggle}>
        <span className="structure-item-control">
          {isOpen ? <Icon icon="minus-square" /> : <Icon icon="plus-square" />}
        </span>
        {_.get(tariff, 'name')}
      </th>
      {_.map(products, id => <td key={id}></td>)}
    </tr>

    {isOpen && tariff.categories && tariff.categories.map(( category, index ) =>
      <Category key={index} products={products} productsData={productsData} path={`${path}.categories.${index}`} category={category} onEdit={onEdit} />
    )}

  </>

}

export default Tariff;
