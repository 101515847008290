/* eslint-disable import/no-anonymous-default-export */

const TOKEN_NAME = 'pp.oauth_token';

// ACTIONS

export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const SET_GRAVATAR_URL = 'SET_GRAVATAR_URL';

export const setUser = (payload) => ({ type: SET_USER, payload });

export const getUser = () => ({ type: GET_USER });

export const setGravatarUrl = (payload) => ({ type: SET_GRAVATAR_URL, payload });

export const getLocalToken = () => {
  const item = JSON.parse(localStorage.getItem(TOKEN_NAME));
  if (item) {
    return item.token;
  }
  return null;
}

export const getLocalTokenExpiration = () => {
  const item = JSON.parse(localStorage.getItem(TOKEN_NAME));
  if (item) {
    return item.expires;
  }
  return null;
}

export const setLocalToken = (token) => localStorage.setItem(TOKEN_NAME, JSON.stringify(token));

export const removeLocalToken = () => localStorage.removeItem(TOKEN_NAME);

// STORE

export default (state = null, action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_USER]: (state, user) => {
      state = {...state, ...user};
      return state;
    },
    [GET_USER]: (state) => {
      return state;
    },
    [SET_GRAVATAR_URL]: (state, url) => {
      state['gravatarUrl'] = url;
      return state;
    }
  };

  return reducer[type] ? reducer[type](state, payload, action) : state;
}
