/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import _ from 'lodash';

const SPREAD = 5;

export default class Paginator extends React.Component {

  render() {
    const { page, last, count, limit, onClick } = this.props;
    const range = _.range(Math.max(2, page - SPREAD), Math.min(last, page + SPREAD + 1));
    const totalPages = count / limit;

    return (
      <nav className="pagination-wrapper pagination-wrapper--fixed">
        <ul className="pagination">

          <li className={`page-item ${ page === 1 && 'active'}`}>
            <a className="page-link" href="#" onClick={() => onClick(1)}>
              <span>1</span>
            </a>
          </li>

          {page > 1 && count > 1 && <li className={`page-item`}>
            <a className="page-link" href="#" onClick={() => onClick(page - 1)}>
              <span>&lt;</span>
            </a>
          </li>}

          {(range[0] - SPREAD) > (SPREAD) && <>
            <li className="page-item disabled"><span className="page-link">...</span></li>
            <li className={`page-item`}><a className="page-link" href="#"
              onClick={() => onClick((range[0] - SPREAD))}>{ (range[0] - SPREAD) }</a></li>
            <li className="page-item disabled"><span className="page-link">...</span></li>
          </>}

          {totalPages > 1 && range.map(p =>
            <li key={p} className={`page-item ${ page === p && 'active'}`}><a className="page-link" href="#"
              onClick={() => onClick(p)}>{ p }</a></li>
          )}

          {totalPages > 1 && (range[range.length-1] + SPREAD) < (last - SPREAD) && <>
            <li className="page-item disabled"><span className="page-link">...</span></li>
            <li className={`page-item`}><a className="page-link" href="#"
              onClick={() => onClick((range[range.length-1] + SPREAD))}>{ (range[range.length-1] + SPREAD) }</a></li>
            <li className="page-item disabled"><span className="page-link">...</span></li>
          </>}

          {page < last && totalPages > 1 && <li className={`page-item`}>
            <a className="page-link" href="#" onClick={() => onClick(page + 1)}>
              <span>&gt;</span>
            </a>
          </li>}

          {totalPages > 1 && last > 1 && <li className={`page-item ${ page === last && 'active'}`}>
            <a className="page-link" href="#" onClick={() => onClick(last)}>
              <span>{last}</span>
            </a>
          </li>}
        </ul>
      </nav>
    )
  }
}
