import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import Forms from 'components/Forms.jsx';
import LabelValue from 'components/LabelValue.jsx';
import ConfirmDialog from 'components/ConfirmDialog.jsx';

import { crud } from 'store/api';
import { getLoanByType } from 'config/loans';

class Edit extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      editItem: {
        type: 'FIX',
      },
    };

    this.products = props.crudCache('products');
  }

  componentDidMount() {
    this.loadData();

    this.products.get({ status: 'VISIBLE', typeId: getLoanByType('buildingLoan').type }).then(( products ) => {
      const productsItems = products.map(({ id, name, company }) => {
        return { key: id, value: `${company.name} ${name}` };
      });
      this.setState({ products, productsItems });
    });
  }

  loadData() {
    const id = this.getId();
    if (id) {
      return this.api.find(id)
        .then(( data ) => {
          let editItem;
          if (data) {
            editItem = { ...data };
          }
          this.setState({ editItem });
        });
    }
  }

  getTitle() {
    const { editItem } = this.state;

    return !this.isNew() && editItem ?
      `${getLoanByType('buildingLoan').title} / Fix ${_.get(editItem, 'product.company.name')} ${_.get(editItem, 'product.name')} / ${_.get(editItem, 'length')} R` :
      'Nový Fix';
  }

  prepareItemForSave(item) {
    const { length, productId } = item;
    return {
      ...item,
      length: parseInt(length),
      productId: productId ? parseInt(productId) : null,
    };
  }


  render() {
    const { editItem } = this.state;
    const onChange = field => v => this.changeDetailItem(field, v);
    const get = field => _.get(editItem, field);

    return <div>

      <ConfirmDialog {...this.state.confirm} />

      <div className="navbar justify-content-start">
        <div className="col-auto">
          <Link className="p-4" to={this.getEndpoint()}>
            <Icon icon="chevron-left" />
          </Link>
        </div>
        <h1 className="ml-2">{this.getTitle()}</h1>
      </div>

      <div className="container pt-4">

        <Card body>
          <div className="row">

            <LabelValue className="col-sm-4" label="Délka">
              <Forms.Input value={get('length')} onChange={onChange('length')} />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Typ">
              <Forms.Select value={get('type')}
                placeholder="= Vyberte ="
                items={[
                  { key: 'FIX', value: 'fixní' },
                  { key: 'VAR', value: 'variabilní' },
                ]}
                onChange={onChange('type')}
              />
            </LabelValue>
            <LabelValue className="col-sm-4" label="PRIBOR fix">
              <Forms.Input value={get('varPriborFix')} onChange={onChange('varPriborFix')} />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Produkt">
              <Forms.Select value={get('productId')}
                placeholder="= Vyberte ="
                items={this.state.productsItems}
                onChange={onChange('productId')}
                />
            </LabelValue>

            <div className="form-group mb-0 col-sm-12 text-right pt-4">
              <button type="button" className="btn btn-primary font-weight-bold mr-2" onClick={() => this.save()}>
                Uložit
              </button>
              <Link to={this.getEndpoint(this.getId())} className="btn btn-secondary font-weight-bold">
                Zrušit
              </Link>
            </div>
          </div>
        </Card>

        {this.getId() && this.canDo('delete') && <div className="text-center p-3">
          <button className="btn btn-outline-danger p-2" onClick={() => this.delete()}>Smazat</button>
        </div>}

      </div>

    </div>;
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
    crudCache: (entity) => dispatch(crud(entity, { cache: true })),
  })
) (Edit)
