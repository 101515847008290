import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';

import CrudComponent from 'components/CrudComponent.jsx';
import ConfirmDialog from 'components/ConfirmDialog.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
import { showSuccessMessage } from 'services/messages';
import { formatDate } from 'services/utils';

class List extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      data: [],
    };

    this.companies = props.crudCache('companies');
    this.products = props.crudCache('products');
  }

  componentDidMount() {
    this.loadData();

    this.companies.get().then(( companies ) => {
      const companiesItems = companies.map(({ id, name }) => ({ key: id, value: name }));
      this.setState({ companies, companiesItems });
    });
  }

  componentDidUpdate(props) {
    super.checkUpdatePage(props);
  }

  loadData() {
    this.showLoading();
    const { filterCompany } = this.state;

    const params = this.getUrlParams();

    if (filterCompany) {
      params['companyId'] = filterCompany.id;
    }

    return this.api.get(params)
      .then((data) => {
        this.setData(data);
        if (!filterCompany) {
          const counts = {};
          data.forEach(({ company_id }) => {
            if (!counts[company_id]) {
              counts[company_id] = 1;
            } else {
              counts[company_id] = Number(counts[company_id]) + 1;
            }
          });
          this.setState({ companiesCounts: counts });
        }
      })
      .finally(() => this.hideLoading())
    ;
  }

  getTitle() {
    return `Progrese trvalých následků ${this.getTotalFormatted()}`;
  }

  getFilter() {
    return [
      { override: 'progressionCompany', field: 'product.companyId' },
      'progressionProduct',
      { name: 'progression', label: 'Progrese', type: 'text', field: 'progression' },
    ];
  }

  duplicateItem(item) {
    this.confirm('Duplikovat progresi a všechny její hodnoty?', 'success', 'Ano')
      .then(() => {
        this.api.getApi().post(`progressions/${item.id}/duplicate`)
          .then((progression) => {
            if (progression.id) {
              showSuccessMessage('Duplikace progrese úspěšná!');
              window.location = this.getEndpoint(`progressions/${progression.id}`);
            }
          });

      })
  }

  getColumns() {
    return [
      { text: 'Producent', dataField: 'product.company.name', sort: true },
      { text: 'Produkt', dataField: 'product.name', sort: true },
      { text: 'Platnost', dataField: 'product.validityDate', formatter: (v) => formatDate(v),sort: true },
      { text: 'Progrese', dataField: 'progression', sort: true },
      { text: 'Počet hodnot', dataField: 'valuesCount' },
      { text: '', isDummyField: true, dataField: '_actions',
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3" title="Detail">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3" title="Upravit">
              <Icon icon='pen' />
            </Link>}
            {this.canDo('update') && <button className="btn btn-sm btn-outline-secondary mr-3" style={{ border: '0 none' }} title="Duplikovat"
              onClick={() => this.duplicateItem(row)}>
              <Icon icon='copy' />
            </button>}
          </div>) }
    ];
  }

  render() {
    return (
      <div>

        <ConfirmDialog {...this.state.confirm} />

        <div className="navbar m-0 pr-2">
          <h1>{this.getTitle()}</h1>
          {this.canDo('create') && <Link to={this.getEndpoint('new')} className="primary-button">+ progrese</Link>}
        </div>

        {this.renderFilter()}

        <BootstrapTable
          classes="table-sm"
          bootstrap4 hover remote
          bordered={false}
          keyField="id"
          data={this.getData()}
          columns={this.getColumns()}
          onTableChange={this.onTableChange()}
        />

        {this.renderPagination()}

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crudCache: (entity) => dispatch(crud(entity, { cache: true })),
    crud: (entity) => dispatch(crud(entity)),
  })
) (List)
