// import React from 'react';
// import ReactDOM from 'react-dom';
import './style/index.scss';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'reflect-metadata';

// import '@trendmicro/react-checkbox/dist/react-checkbox.css';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
// import 'styles/main.scss';

import * as serviceWorker from 'serviceWorker';
// import ErrorBoundary from 'ErrorBoundary';
import ToastMessages from 'components/ToastMessages';
import App from 'App';
import store from 'store';
// import { handleErrors } from 'utils';

// const renderError = (error) => {
//   console.error('General Error: ', error);
//   if (error && error.responseCode >= 500) {
//     ReactDOM.render(
//       <ErrorBoundary isError error={error} />,
//       document.getElementById('root')
//     );

//   } else {
//     handleErrors(error);
//   }
// }

// window.onerror = (message, source, lineno, colno, error) => renderError(error)
// window.onunhandledrejection = (event) => renderError(event.reason);
// window.addEventListener('error', (event) => renderError(event));
// window.addEventListener('unhandledRejection', (event) => renderError(event.reason));

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastMessages />
  </Provider>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
