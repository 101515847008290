import React from 'react';
import { connect } from 'react-redux';

const LoaderOverlay = ({ show = true, label = "Načítám..." }) => {

  return show && <div className="loader-overlay">
    <div className="loader">{ label }</div>
  </div>;

}

export default connect(
  state => ({
    show: state.loading,
  })
) (LoaderOverlay)

