/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
import Aside from 'components/Aside.jsx';
import Form from 'components/Forms.jsx';

import Tariff from './components/Tariff';
import ProductsSelector from './components/ProductsSelector';
import EditParameter from './components/EditParameter';
import { Badge } from 'react-bootstrap';
import { ARCHIVE, HIDDEN, VISIBLE } from 'config/products';

class ProductStructure extends CrudComponent {

  constructor(props) {
    super(props);
    this.initEntities('productStructure', 'structure/tree');

    this.state = {
      ...super.state,

      edit: {
        id: null,
        parameterId: null,
        productId: null,
      },

      typeId: null,
      types: [],

      showProducts: false,
      products: [],
      productsByIds: {},
      selectedProducts: {},
      productsData: {},
    }

    this.onEdit.bind(this);
    this.products = props.crudCache('products');
  }

  componentDidMount() {
    this.loadData()
      .then(() => {
        const { data } = this.state;
        this.setState({
          types: _.map(data, ({ type }) => {
            const { id: key, name: value } = type;
            return { key, value };
          }),
        });

        if (this.getTypeId()) {
          this.setTypeId(this.getTypeId());
        }
      });
  }

  loadData() {
    this.showLoading();
    return this.api.get(this.getUrlParams())
      .then((data) => this.setState({ data }))
      .finally(() => this.hideLoading());
  }

  loadDataPartial(edit) {
    const { productId } = edit;

    this.showLoading();
    return this.loadProductStructure(productId)
      .finally(() => this.hideLoading());
  }

  getTitle() {
    return 'Produktová struktura';
  }

  getTypeId() {
    const { type } = _.get(this.props, 'match.params');
    return type || null;
  }

  setTypeId(typeId) {
    this.setState({
      typeId, selectedProducts: {},
    })
    this.history.push(`/${typeId}`);

    this.products.get({ itemsPerPage: 1000, typeId, 'order[company.name]': 'asc', 'order[status]': 'desc', 'order[name]': 'asc' })
      .then((data) => {
        const products = {};
        const productsByIds = {};
        const sortIndices = { [VISIBLE]: 1, [HIDDEN]: 2, [ARCHIVE]: 3 };

        _.forEach(data, ({ id, company, name, validityDate, expirationDate, status }) => {
          if (!products[company.name]) {
            products[company.name] = {
              company, products: [],
            };
          }
          const sortIndex = sortIndices[status];

          products[company.name].products.push({ id, name, validityDate, expirationDate, status, sortIndex });
          productsByIds[id] = {
            name, validityDate, expirationDate, status, company
          };
        });

        const sortedProducts = _.chain(products).values().map(v => {
          return { ...v, products: _.sortBy(v.products, 'sortIndex') };
        }).value();

        this.setState({ products: sortedProducts, productsByIds }) ;
      });
  }

  getTariffs() {
    const { typeId, data } = this.state;
    if (typeId) {
      // eslint-disable-next-line eqeqeq
      return _.get(_.find(data, ({ type }) => type.id == typeId), 'tariffs') || [];
    }
    return [];
  }

  showAddProduct() {
    this.setState({ showProducts: true });
  }

  hideAddProduct() {
    this.setState({ showProducts: false });
  }

  getSelectedProductsIds() {
    return _.map(this.state.selectedProducts, (v, key) => v ? key : false).filter(Boolean);
  }

  removeFilterProduct(id) {
    this.setState(state => _.set(state, `selectedProducts.${id}`, false));
  }

  selectProduct(isSelected, productId) {
    this.setState(state => _.set(state, `selectedProducts.${productId}`, isSelected))
    if (isSelected && !_.get(this.state, `productsData.${productId}`)) {
      this.loadProductStructure(productId);
    }
  }

  loadProductStructure(productId) {
    return this.api.getApi().get(`structure/product/${productId}`)
      .then((data) => this.setState(state => _.set(state, `productsData.${productId}`, data)));
  }

  onEdit(id, parameterId, productId) {
    this.setState({ edit: { id, parameterId, productId } });
  }

  closeEdit() {
    this.setState({ edit: { id: null, parameterId: null, productId: null }})
  }

  getColumns() {
    return [
      { text: 'Typ', dataField: 'tariffCategory.tariff.type', formatter: ({id, name}) => `(${id}) ${name}` },
      { text: 'Tarif', dataField: 'tariffCategory.tariff', formatter: ({id, name}) => `(${id}) ${name}` },
      { text: 'Kategorie', dataField: 'tariffCategory.category', formatter: ({id, name}) => `(${id}) ${name}`},
      { text: 'Parametr', dataField: 'parameter', formatter: ({id, name}) => `(${id}) ${name}` },
      { text: '', isDummyField: true, dataField: '_actions',
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3">
              <Icon icon='pen' />
            </Link>}
          </div>) }
    ];
  }

  getFilter() {
    return [
      'type',
    ];
  }

  render() {
    const { edit, typeId, types, showProducts } = this.state;
    const { history } = this.props;

    return (
      <div>
        <Aside className="aside--sm" on={!!edit.parameterId}>
          <EditParameter aside id={edit.id} params={{
              tariffCategoryParameterId: edit.parameterId,
              productId: edit.productId,
            }} entity="structure/product_tariff_category_parameters" history={history}
            onSave={() => this.loadDataPartial(edit).then(() => this.closeEdit() )}
            onClose={() => this.closeEdit() }/>
        </Aside>

        <div className="navbar m-0 pr-2">
          <h1>
            {this.getTitle()}
          </h1>
        </div>

        <div className="crud-filter m-2">
          <div className="crud-filter-field crud-filter-field--inline">
            <label>Typ:</label>
            <Form.Select name="type" value={typeId} items={types} placeholder="= Vyberte typ =" style={{ minWidth: 150 }}
              onChange={(v) => this.setTypeId(parseInt(v))} />
          </div>
          {typeId && <div className="crud-filter-field crud-filter-field--inline">
            <label>Produkty:</label>
            <div className="crud-filter-field-values">
              {_.map(this.getSelectedProductsIds(), id => {
                if (!this.state.productsByIds[id]) {
                  return '';
                }
                const { name, company } = this.state.productsByIds[id];
                return <Badge key={id} className="crud-filter-badge" variant="primary">
                  {`${company.shortName} ${name}`}
                  <Icon className="crud-filter-badge-remove" icon="minus-circle" onClick={() => this.removeFilterProduct(id) }/>
                </Badge>
              })}
              <button className="btn btn-link btn-sm" onClick={() => this.showAddProduct()}>+ produkt</button>
            </div>
          </div>}
        </div>

        {showProducts && <ProductsSelector
          products={this.state.products}
          selectedProducts={this.state.selectedProducts}
          onSelectProduct={ (isSelected, productId) => this.selectProduct(isSelected, productId) }
          onClose={() => this.hideAddProduct()}
        />}

        {typeId && <div className="product-structure">
          <table className="table table-fluid">

            <thead>
              <tr>
                <th>
                  Struktura
                </th>
                {_.map(this.getSelectedProductsIds(), id => {
                  if (this.state.productsByIds[id]) {
                    const { name, company } = this.state.productsByIds[id];
                    return <th key={id}>{`${company.shortName} ${name}`}</th>
                  }
                })}
              </tr>
            </thead>

            <tbody>
              {_.map(this.getTariffs(), (tariff, index) =>
                <Tariff
                  key={index}
                  index={index}
                  tariff={tariff}
                  products={this.getSelectedProductsIds()}
                  productsData={this.state.productsData}
                  onEdit={(id, parameterId, productId) => this.onEdit(id, parameterId, productId)}
                />
              )}
            </tbody>
          </table>
        </div>}

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
    crudCache: (entity) => dispatch(crud(entity, { cache: true })),
  })
) (ProductStructure)
