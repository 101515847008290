/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import Forms from 'components/Forms.jsx';
import LabelValue from 'components/LabelValue.jsx';
import ConfirmDialog from 'components/ConfirmDialog.jsx';

import { crud } from 'store/api';

class EditCategory extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };

    this.categories = props.crudCache('structure/tariff_categories');
    this.types = props.crudCache('types');
  }

  componentDidMount() {
    this.loadData();

    this.categories.get({ itemsPerPage: 1000, 'order[tariff.type.name]': 'asc', 'order[tariff.name]': 'asc', 'order[category.name]': 'asc' }).then(this.setCodebookData('categories', ({ id, category, tariff }) => {
      return { key: id, value: `(${id}) ${tariff.type.name} / ${tariff.name} / ${category.name}` };
    }));
  }

  loadData() {
    const id = this.getId();

    if (id) {
      this.showLoading();
      return this.api.find(id)
        .then(( data ) => {
          let editItem;
          if (data) {
            editItem = { ...data, parameterName: _.get(data, 'parameter.name') };
          }
          this.setState({ editItem });
        })
        .finally(() => this.hideLoading());

    } else {
      // new item
      const { params } = this.props;
      params && this.setState({
        editItem: params,
      });
    }
  }

  getId() {
    return _.get(this.props, 'id', null) || null;
  }

  getTitle() {
    return 'Parametr v tarifní kategorii';
  }

  prepareItemForSave(item) {
    return {
      ...item,
      tariffCategoryId: item.tariffCategoryId ? parseInt(item.tariffCategoryId) : null,
      parameterId: item.parameterId ? parseInt(item.parameterId) : ( item.newParameter ? 0 : null ),
      parameterName: item.parameterName ? item.parameterName : null,
      isVisible: Boolean(item.isVisible),
      isRecommendation: item.isRecommendation ? Boolean(item.isRecommendation) : null,
      sort: parseInt(item.sort || 0),
      parameter: null,
      tariffCategory: null,
    };
  }

  render() {
    const { editItem } = this.state;
    const onChange = field => v => this.changeDetailItem(field, v);
    const get = field => _.get(editItem, field);

    return <div>

      <ConfirmDialog {...this.state.confirm} />

      <div className="navbar justify-content-start">
        <div className="col-auto">
          <Link className="p-4" to={this.getEndpoint()} onClick={() => {
            if (this.props.aside && this.props.onClose) {
              this.props.onClose();
              return false;
            }
          }}>
            <Icon icon={ this.props.aside ? 'times' : 'chevron-left' } />
          </Link>
        </div>
        <h1 className="ml-2">{this.getTitle()}</h1>
      </div>

      <div className="container pt-4">

        <Card body>
          <div className="row">

            <LabelValue className="col-sm-12" label="Kategorie">
              <Forms.Select value={get('tariffCategoryId')}
                placeholder="= Vyberte ="
                items={this.state.categories}
                onChange={onChange('tariffCategoryId')}
                />
            </LabelValue>

            <LabelValue className="col-sm-12" label="Parametr">
              <Forms.Input value={get('parameterName')} onChange={onChange('parameterName')} />
            </LabelValue>

            <LabelValue className="col-sm-6">
              <Forms.Checkbox label="Viditelný" value={get('isVisible')} onChange={onChange('isVisible')} />
            </LabelValue>

            <LabelValue className="col-sm-6">
              <Forms.Checkbox label="Doporučení" value={get('isRecommendation')} onChange={onChange('isRecommendation')} />
            </LabelValue>

            <div className="form-group mb-0 col-sm-12 text-left pt-4">
              <button type="button" className="btn btn-primary font-weight-bold mr-2" onClick={() => this.save()}>
                Uložit
              </button>
              <Link to={this.getEndpoint(this.getId())} className="btn btn-secondary font-weight-bold" onClick={(e) => {
                if (this.props.aside && this.props.onClose) {
                  e.preventDefault();
                  this.props.onClose();
                }
              }}>
                Zrušit
              </Link>
            </div>
          </div>
        </Card>

      </div>

    </div>;
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
    crudCache: (entity) => dispatch(crud(entity, { cache: true })),
  })
) (EditCategory)
