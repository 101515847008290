import React, { useState } from 'react';
import _ from 'lodash';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import Icon from 'components/Icon';
import EditPanel from './EditPanel';
import Parameter from './Parameter';
import { classes } from 'services/utils';

const Category = ({ index, path, category, onEdit }) => {

  const [ isOpen, setIsOpen ] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return <div>
    <label>
      <span className="structure-item-name" onClick={toggle}>
        <span className="structure-item-control">
          {isOpen ? <Icon icon="minus-square" /> : <Icon icon="plus-square" />}
        </span>
        {_.get(category, 'category.name')}
      </span>
      <EditPanel type="category" id={category.id} path={path} onEdit={onEdit} />
    </label>

    {isOpen && <Droppable type="category" droppableId={`category/${index}-${category.id}/${path}.parameters`}>
      {(provided) => (
        <div ref={provided.innerRef}>
          {category.parameters.map(( parameter, index ) =>
          <Draggable
            key={parameter.id}
            draggableId={`parameter/${parameter.id}`}
            index={index}>

            {( provided, snapshot, ) => <div className={classes({'structure-item': true, 'structure-item--dragging': snapshot.isDragging })}
                ref={provided.innerRef} {...provided.draggableProps}>
              <div className="structure-drag-handle" {...provided.dragHandleProps}></div>
              <Parameter key={index} parameter={parameter} path={`${path}.parameters.${index}`} onEdit={onEdit} />
            </div>}

          </Draggable> )}
          {provided.placeholder}
          <div className="structure-add-button">
            <button className="btn btn-link" onClick={() => onEdit(null, 'parameter', `${path}.parameters.${index}`, { tariffCategoryId: category.id, sort: category.parameters.length })}>+ parametr</button>
          </div>
        </div>
      )}
    </Droppable>}

  </div>

}

export default Category;
