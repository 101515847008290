
import _ from 'lodash';

/**
 * Formats date from string or a date instance.
 * @param {String} date
 * @param {String} format
 */
export const formatDate = (date, format = 'YYYY-MM-DD') => {
  if (!date) {
    return null;
  }
  try {
    return new Date(date).toLocaleDateString(
      'cs-cz' , { timeZone: 'CET' }
    );

  } catch (error) {
    console.error(error);
  }
  return null;
}


/**
 * Formats a number with thousands separator.
 * @param {Number} value
 */
export const formatNumber = (value, separator = ' ') => {
  if (!_.isNil(value)) {
    return value.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  }
  return value;
}

/**
 * Formats a decimal value with dots replacement for commas.
 * @param {String} value
 */
export const formatDecimal = (value, digits = 2) => {
  if (value === '' || _.isNil(value)) {
    return '';
  }

  let number = Number(value);

  if (isNaN(number)) {
    return value;
  }

  if (digits !== null) {
    number = number.toFixed(2);
  }

  return number.toString().replace('.', ',');
}

/**
 * Formats a decimal value with dots replacement for commas.
 * @param {String} value
 */
export const parseDecimal = (value) => {
  return value ? parseFloat(value.toString().replace(',', '.')) : value;
}


/**
 * Resolves the class names from the given object.
 * Classes can be defined as keys with their boolean value:
 * ```
 *   {
 *     'className1': true,
 *     'className2': isSomethingElseTrue()
 *   }
 * ```
 * @param {Object} obj
 */
export const classes = (obj) => {
  return _.chain(obj).map((v, k) => v ? k : null).filter().join(' ').value();
}

export const formatBooleanValue = (value, trueValue = 'ano', falseValue = 'ne') => Boolean(value) ? trueValue : falseValue;

export const formatCodebookValue = (value, items) => _.get(items, value, value) || value;

export const preventDefault = cb => e => {
  e.preventDefault();
  return cb(e);
}

