/* eslint-disable no-undef */
import React from 'react';

import LabelValue from './LabelValue';

import Form from './Forms';

export default function CrudFilterField({ label, type, format, value, field, items, placeholder = null, onChange, onSubmit }) {

  const getLabel = () => {
    if (type === 'checkbox') {
      return null;
    }
    return label;
  }

  return <div className="crud-filter-field">
    <LabelValue label={getLabel()}>

      {type === 'text' &&
        <Form.Input name={field} value={value} onChange={(v) => onChange(v)} placeholder={placeholder} onEnterKey={() => onSubmit()} />}

      {type === 'number' &&
        <Form.Input name={field} format={format} value={value} placeholder={placeholder} onChange={(v) => onChange(v)} onEnterKey={() => onSubmit()} />}

      {type === 'select' &&
        <Form.Select name={field} value={value} onChange={(v) => onChange(v, true)} items={items} placeholder={placeholder || '= Vše ='} />}

      {type === 'date' &&
        <Form.DatePicker name={field} value={value} placeholder={placeholder} onChange={(v) => onChange(v)} />}

      {type === 'checkbox' &&
        <Form.Checkbox name={field} label={label} value={value} onChange={(v) => onChange(v === true ? v : null, true)} />}

    </LabelValue>
  </div>

}
