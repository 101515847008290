import React from 'react';
import Icon from 'components/Icon.jsx';
import { formatNumber } from 'services/utils';

import ProgressionValueEdit from './ProgressionValueEdit';

class ProgressionValue extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: false,
    };

  }

  onEdit = () => this.setState({ edit: true });

  onDelete = (value) => {
    const { onDelete } = this.props;
    return onDelete && onDelete(value);
  }

  onClose = newValue => {
    const { onChange } = this.props;
    if (newValue && onChange) {
      onChange(newValue);
    }
    this.setState({ edit: false });
  };

  render() {
    const { value = {} } = this.props;
    const { edit } = this.state;

    return ( edit ?
      <ProgressionValueEdit value={value} onClose={newValue => this.onClose(newValue)} /> :
      <tr>
        <td>
          {value.degree}
        </td>
        <td>
          {formatNumber(value.value)}
        </td>
        <td style={{ border: 0 }}>
          <Icon icon="pen" className="cursor-pointer" onClick={() => this.onEdit()} />
          <Icon icon="trash" className="text-danger ml-3 hover-opacity cursor-pointer" onClick={() => this.onDelete(value)}  />
        </td>
      </tr>
    );
  }

}

export default ProgressionValue;
