import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
import { formatBooleanValue } from 'services/utils';

class List extends CrudComponent {

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(props) {
    super.checkUpdatePage(props);
  }

  loadData() {
    this.showLoading();
    return this.api.get(this.getUrlParams())
      .then((data) => this.setData(data))
      .finally(() => this.hideLoading());
  }

  getTitle() {
    return `Správa uživatelů ${this.getTotalFormatted()}`;
  }

  getFilter() {
    return [
      'name',
      { name: 'email', label: 'E-mail', type: 'text', field: 'email' },
      { name: 'active', label: 'Aktivní', type: 'select', items: [{ key: 1, value: 'ano' }, { key: 0, value: 'ne' }], field: 'active' },
      { name: 'isAdmin', label: 'Je administrátor', type: 'checkbox', field: 'isAdmin' },
      { name: 'isAssistant', label: 'Je asistent', type: 'checkbox', field: 'isAssistant' },
    ];
  }

  getColumns() {
    return [
      { text: 'Jméno', dataField: 'name', sort: true },
      { text: 'E-mail', dataField: 'email', sort: true },
      { text: 'Aktivní', dataField: 'active', sort: true, formatter: (v) => formatBooleanValue(v) },
      { text: 'Role', dataField: 'isAdmin', sort: true,
        formatter: (v, { isAdmin, isAssistant }) => {
          const roles = [];
          isAdmin && roles.push('admin');
          isAssistant && roles.push('asistentka');
          return roles.join(',');
        } },
      { text: '', isDummyField: true, dataField: '_actions',
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3">
              <Icon icon='pen' />
            </Link>}
          </div>) }
    ];
  }

  render() {
    return (
      <div>

        <div className="navbar m-0 pr-2">
          <h1>{this.getTitle()}</h1>
          {this.canDo('create') && <Link to={this.getEndpoint('new')} className="primary-button">+ nový</Link>}
        </div>

        {this.renderFilter()}

        <BootstrapTable
          classes="table-sm"
          bootstrap4 hover remote
          bordered={false}
          keyField="id"
          data={this.getData()}
          columns={this.getColumns()}
          onTableChange={this.onTableChange()}
        />

        {this.renderPagination()}

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (List)
