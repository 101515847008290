import React from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';

const parseValue = (value) => value === undefined || value === null ? '' : value;

const parseChangedValue = (value) => value === '' || value === undefined ? null : value;

class FormSelect extends React.Component {
  render() {
    const { value = '', items, placeholder, onChange, ...props } = this.props;

    let itemsArray = items;
    if (!_.isArray(items)) {
      itemsArray = _.map(items, (value, key) => ({ key, value }));
    }

    return (
      <select {...props} className="form-control" value={parseValue(value)}
        onChange={(e) => onChange(e.target.value === 'null' ? null : parseChangedValue(e.target.value))}>
        {placeholder && <option value="">{placeholder}</option>}
        {itemsArray && itemsArray.map(({ key, value }) => <option key={key} value={key}>{value}</option>)}
      </select>
    )
  }
}

class FormInput extends React.Component {
  render() {
    const { value = '', type = 'text', onChange, onEnterKey, ...props } = this.props;

    // const parseFormatValue = value => {
    //   switch (format) {
    //     case 'string': return String(value);
    //     case 'number': return Number(value);
    //     case 'integer': return parseInt(value);
    //     case 'float': return parseDecimal(value);
    //     default: return value;
    //   }
    // }
    if (type === 'number') {
      return (
        <NumberFormat value={parseValue(value)} className="form-control"
          allowedDecimalSeparators={[',', '.']}
          decimalSeparator=","
          thousandSeparator=" " isNumericString={true}
          onValueChange={({ value }) => onChange(parseChangedValue(value)) }/>
      )
    }

    return (
      <input {...{ ...props, type, value: parseValue(value) }} className="form-control"
        onChange={(e) => onChange(parseChangedValue(e.target.value))}
        // onBlur={(e) => onChange(parseFormatValue(parseChangedValue(e.target.value)))}
        onKeyPress={({ key }) => key === 'Enter' && onEnterKey && onEnterKey()}
      />
    )
  }
}

class FormTextarea extends React.Component {
  render() {
    const { value = '', rows = 6, onChange, onEnterKey } = this.props;
    return (
      <textarea className="form-control" { ...{ value: parseValue(value), rows }}
        onChange={(e) => onChange(parseChangedValue(e.target.value))}
        onKeyPress={({ key }) => key === 'Enter' && onEnterKey && onEnterKey()}
      />
    )
  }
}

class FormCheckbox extends React.Component {
  render() {
    const { value = null, label, type = 'checkbox', onChange } = this.props;
    return (
      <label className="form-checkbox">
        <input type={type} className="form-control" checked={Boolean(value)}
          onChange={(e) => onChange(Boolean(e.target.checked))}
        />
        <span>{ label }</span>
      </label>
    )
  }
}

class FormDatePicker extends React.Component {
  render() {
    let { value = null, onChange } = this.props;
    if (value && !(value instanceof Date)) {
      value = new Date(value);
    }
    return (
      <DatePicker className="form-control"
        dateFormat="d. M. yyyy"
        selected={value} onChange={(date) => {
          date.setHours(5);
          onChange(date)
        }}
      />
    )
  }
}

export const Forms = {
  Select: FormSelect,
  Input: FormInput,
  Textarea: FormTextarea,
  Checkbox: FormCheckbox,
  DatePicker: FormDatePicker,
};

export default Forms;
