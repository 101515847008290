/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import PasswordRecover from './PasswordRecover';

export default () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/me/recover-password/:token?" component={PasswordRecover} />
        {/* <Route exact path={`/me/new`} component={newComponent} />
        <Route exact path={`/me/:id(\\d+)`} component={detailComponent} />
        <Route exact path={`/me`} component={listComponent} /> */}
        <Redirect to={`/me`} />
      </Switch>
    </Router>
  );
}
