import React, { PureComponent } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPen, faSearch, faTrash, faPlus, faChevronLeft, faCopy,
  faCaretDown, faCaretRight, faTimes, faPlusSquare, faMinusSquare, faEyeSlash,
  faSync, faMinusCircle, faInfoCircle, faArchive
} from '@fortawesome/free-solid-svg-icons'

// import {
//   faFilePdf
// } from '@fortawesome/free-regular-svg-icons'

library.add(
  faPen, faSearch, faTrash, faPlus, faChevronLeft, faCopy,
  faCaretDown, faCaretRight, faTimes, faPlusSquare, faMinusSquare, faEyeSlash,
  faSync, faMinusCircle, faInfoCircle, faArchive
);

export default class Icon extends PureComponent {

  render() {
    return <FontAwesomeIcon {...this.props} />
  }

}
