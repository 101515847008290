import _ from 'lodash';

export default class CrudApi {

  constructor(api, entity, properties = {}) {
    this.api = api;
    this.entity = entity;
    this.properties = properties;

    this.cache = {};
  }

  getApi() {
    return this.api;
  }

  getCache(collection) {
    return new Promise((res, reject) => {
      const val = _.get(this.cache, collection, null) || null;
      val ? res(val) : reject(val);
    });
  }

  hasCache(collection) {
    const val = _.get(this.cache, collection, null) || null;
    return val !== null;
  }

  clearCache(collection) {
    this.cache[collection] = null;
  }

  clearAllCache() {
    this.cache = {};
  }

  get(params = null) {
    const { cache } = this.properties;

    const hash = params ? `.${this.getSafeB64String(params)}` : '';
    const collection = `get${ hash }`;

    if (cache && this.hasCache(collection)) {
      return this.getCache(collection);
    }
    return this.api.get(this.entity, { params })
      .then(data => {
        if (cache) {
          this.cache[collection] = data;
        }
        return data;
      });
  }

  find(id) {
    return this.api.get(`${this.entity}/${id}`);
  }

  create(data) {
    return this.api.post(this.entity, data);
  }

  update(id, data) {
    return this.api.put(`${this.entity}/${id}`, data);
  }

  delete(id) {
    return this.api.delete(`${this.entity}/${id}`);
  }

  getSafeB64String(data) {
    return btoa(
      unescape(
        encodeURIComponent(
          JSON.stringify(data)
        )
      )
    );
  }

}
