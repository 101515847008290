import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
import { formatCodebookValue, formatDate } from 'services/utils';
import { getConfig } from 'config';

class List extends CrudComponent {

  constructor(props) {
    super(props);

    const defaultFilter = {
      // status: 'VISIBLE',
    };

    this.state = {
      ...this.state,
      defaultFilter,
      filter: {...defaultFilter},
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(props) {
    super.checkUpdatePage(props);
  }

  loadData() {
    this.showLoading();
    return this.api.get(this.getUrlParams())
      .then((data) => this.setData(data))
      .finally(() => this.hideLoading());
  }

  getTitle() {
    return `Produkty ${this.getTotalFormatted()}`;
  }

  getColumns() {
    return [
      { text: 'ID', dataField: 'id', sort: true },
      { text: 'Typ', dataField: 'type.name', formatter: (_, {type}) => type && `${type.name} (${type.id})`, sort: true  },
      { text: 'Název', dataField: 'name', sort: true },
      { text: 'Producent', dataField: 'company.shortName', formatter: (_, {company}) => company && `${company.shortName} (${company.id})`, sort: true  },
      { text: 'Prod. skupina', dataField: 'productGroup', formatter: (v) => v && `${v.name} (${v.id})`, sort: true  },
      { text: 'Stav', dataField: 'status', formatter: (v) => formatCodebookValue(v, getConfig('products.types')), sort: true },
      { text: 'Platnost', dataField: 'validityDate', formatter: (v) => formatDate(v), sort: true },
      { text: 'Expirace', dataField: 'expirationDate', formatter: (v) => formatDate(v), sort: true },
      { text: '', isDummyField: true, dataField: '_actions',
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3">
              <Icon icon='pen' />
            </Link>}
          </div>) }
    ];
  }

  getFilter() {
    return [
      'type',
      'name',
      'company',
      { name: 'status', label: 'Stav', type: 'select', items: getConfig('products.types'), field: 'status' },
      { name: 'validityDate', label: 'Platnost od', type: 'date', field: 'validityDate[after]' },
      { name: 'expirationDate', label: 'Expirace od', type: 'date', field: 'expirationDate[after]' },
      { name: 'expirationDate', label: 'Expirace do', type: 'date', field: 'expirationDate[before]' },
    ];
  }

  render() {
    return (
      <div>
        <div className="navbar m-0 pr-2">
          <h1>{this.getTitle()}</h1>
          {this.canDo('create') && <Link to={this.getEndpoint('new')} className="primary-button">+ nový</Link>}
        </div>

        {this.renderFilter()}

        <BootstrapTable
          classes="table-sm"
          bootstrap4 hover remote
          bordered={false}
          keyField="id"
          data={this.getData()}
          columns={this.getColumns()}
          onTableChange={this.onTableChange()}
        />

        {this.renderPagination()}

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (List)
