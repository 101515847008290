/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    title: 'Produkty',
    items: [
      {
        route: 'products',
        title: 'Produkty',
      },
      {
        route: 'productStructure',
        title: 'Produktová struktura',
      },
      // {
      //   route: 'productGroups',
      //   title: 'Produktové skupiny',
      // },
      {
        route: 'structure',
        title: 'Struktura',
      },
    ]
  },
  {
    route: 'progressions',
    title: 'Progrese',
  },
  {
    title: 'Úvěry',
    items: [
      {
        title: 'Hypotéky',
        items: [
          {
            route: 'mortgage.rates',
            title: 'Sazby',
          },
          {
            route: 'mortgage.discounts',
            title: 'Slevy',
          },
          {
            route: 'mortgage.purposes',
            title: 'Účely',
          },
          {
            route: 'mortgage.fixes',
            title: 'Fixace',
          },
        ]
      },
      {
        title: 'Americké hypotéky',
        items: [
          {
            route: 'americanMortgage.rates',
            title: 'Sazby',
          },
          {
            route: 'americanMortgage.discounts',
            title: 'Slevy',
          },
          {
            route: 'americanMortgage.purposes',
            title: 'Účely',
          },
          {
            route: 'americanMortgage.fixes',
            title: 'Fixace',
          },
        ]
      },
      {
        title: 'Spotřební úvěry',
        items: [
          {
            route: 'consumerCredit.rates',
            title: 'Sazby',
          },
          {
            route: 'consumerCredit.discounts',
            title: 'Slevy',
          },
          {
            route: 'consumerCredit.purposes',
            title: 'Účely',
          },
          {
            route: 'consumerCredit.fixes',
            title: 'Fixace',
          },
        ]
      },
      {
        title: 'Stavební spoření',
        items: [
          {
            route: 'buildingLoan.rates',
            title: 'Sazby',
          },
          {
            route: 'buildingLoan.discounts',
            title: 'Slevy',
          },
          {
            route: 'buildingLoan.purposes',
            title: 'Účely',
          },
          {
            route: 'buildingLoan.fixes',
            title: 'Fixace',
          },
        ]
      },
    ]
  },
  {
    route: 'feeStructure',
    title: 'Poplatky',
  },
  {
    title: 'Číselníky',
    items: [
      {
        route: 'companies',
        title: 'Producenti',
      },
      {
        route: 'types',
        title: 'Typy',
      },
    ]
  },
  {
    title: 'Nastavení',
    items: [
      {
        route: 'users',
        title: 'Uživatelé',
      },
    ]
  },
];
