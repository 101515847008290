import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setUser, getLocalToken, removeLocalToken, setGravatarUrl, getLocalTokenExpiration, setLocalToken } from 'store/user';
import { showErrorMessage } from 'services/messages';
import { initAcl, canDo } from 'services/auth';
import { getCurrentUser, getGravatarUrl, postRefresh } from 'store/api';
import { isProtected } from 'config/routes';

class UserContext extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.interval = null;

  }

  componentDidMount() {
    this.loadUser()
      .then((user) => user && this.loadGravatarUrl(user))
      .then(() => initAcl())
      .then(() => {
        if (this.props.user && getLocalToken() && !canDo('login')) {
          showErrorMessage('Uživatel nemá povoleno se přihlásit do administrace');
          removeLocalToken();
          this.redirectToLogin();
        }
      })
      .finally(() => this.setState({ loaded: true }));

    // check for possible auth token expiration, once an hour)
    this.checkTokenExpiration = this.checkTokenExpiration.bind(this);
    this.checkTokenExpiration(true);
    this.interval = window.setInterval(this.checkTokenExpiration, 60 * 60);
  }

  clearRefreshInterval() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    this.clearRefreshInterval();
  }

  checkTokenExpiration(redirectToLogin = false) {
    const expiration = getLocalTokenExpiration();
    const now = new Date();
    if (expiration) {
      const diff = (new Date(expiration)) - now;

      // refresh token 1 hour before expiration
      if (diff < (60 * 60 * 5 * 1000)) {
        console.log('Authorization token expired, trying refresh...');
        postRefresh().then(( newToken ) => {
          if (newToken) {
            const { token, expires } = newToken;
            setLocalToken({ token, expires });
            console.log('Token refreshed successfully.');
          }
        }).catch(function(err) {
          if (err.status === 401) {
            console.error('Session expired');
            removeLocalToken();
            if (this) {
              this.clearRefreshInterval();
            }
            showErrorMessage('Přihlášení vypršelo, přihlaste se znovu');
            if (redirectToLogin) {
              this ? this.redirectToLogin(false) : (window.location = '/login');
            }
          }
        })
      }
    }
  }

  loadUser() {
    if (!this.isProtected()) {
      return Promise.resolve(null);
    }

    if (getLocalToken() === null) {
      this.props.setUser(null);
      this.redirectToLogin(false);
      return Promise.reject();
    }

    if (this.props.user) {
      return Promise.resolve(this.props.user);
    }

    return getCurrentUser()
      .then((user) => {
        this.props.setUser(user);
        return user;
      })
      .catch(error => {
        if (error.status && error.status === 400) {
          this.redirectToLogin(false);
        }
      });
  }

  redirectToLogin(soft = true) {
    const { location, history } = this.props;
    const path = location.pathname;
    if (path && (path !== '/login')) {
      if (soft) {
        history.push('/login');
      } else {
        window.location = '/login';
      }
    }
  }

  isProtected() {
    return isProtected(this.props.location.pathname);
  }

  loadGravatarUrl({ user }) {
    if (user && user.emailHash) {
      return getGravatarUrl(user.emailHash)
        .then((thumbnailUrl) => this.props.setGravatarUrl(thumbnailUrl))
        .catch(() => {});
    }
  }

  render() {
    return this.state.loaded && this.props.children;
  }

}

export default connect(
  state => ({
    user: state.user,
  }),
  dispatch => ({
    setUser: (user) => dispatch(setUser(user)),
    setGravatarUrl: (url) => dispatch(setGravatarUrl(url)),
  })
) (withRouter(UserContext))
