// TariffCategoryParameters

/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Structure from './Structure';
// import TariffCategoryParameters from './TariffCategoryParameters';

export default () => {

  // const menu = [
  //   {
  //     path: '/',
  //     label: 'Struktura',
  //   },
  //   {
  //     path: '/tariffs',
  //     label: 'Tarify',
  //   },
  //   {
  //     path: '/tariffCategories',
  //     label: 'Kategorie',
  //   },
  //   {
  //     path: '/tariffCategoryParameters',
  //     label: 'Parametry',
  //   },
  // ]

  return (
    <>
      <Router basename="/structure">
        {/* <div className="content-menu">
          <ul>
            {menu.map(({ path, label }, i) => <li key={i}><NavLink exact activeClassName="active" to={path}>{label}</NavLink></li>)}
          </ul>
        </div> */}
        <Switch>

          <Route exact path="/" render={(props) => <Structure {...props} />} />
          {/* <Route exact path="/tariffCategoryParameters" render={(props) => <TariffCategoryParameters {...props} />} />

          <Redirect to="/" /> */}

        </Switch>
      </Router>
    </>
  );
}
