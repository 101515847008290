import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import Forms from 'components/Forms.jsx';
import { crud } from 'store/api';

class Edit extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      editItem: {
        companyId: '',
        productId: '',
        progression: '',
      },
      companyProducts: [],
    };

    this.products = props.crudCache('products');
  }

  componentDidMount() {
    this.loadData();

    this.products.get({ status: 'VISIBLE', typeId: 1 }).then(( products ) => {
      const productsItems = products.map(({ id, name, company }) => {
        return { key: id, value: `${company.name} ${name}` };
      });
      this.setState({ products, productsItems });
    });

  }

  loadData() {
    const id = this.getId();
    if (id) {
      return this.api.find(id)
        .then(( data ) => {
          let editItem;
          if (data) {
            const { productId, progression } = data;
            editItem = { productId, progression };
          }
          this.setState({ item: data, editItem });
        });
    }
  }


  getTitle() {
    const { item } = this.state;

    return !this.isNew() && item ?
      `Editace progrese trvalých následků ${_.get(item, 'product.company.name')} ${_.get(item, 'product.name')} ${item.progression}` :
      'Nová progrese trvalých následků';
  }

  prepareItemForSave(item) {
    const { productId, progression } = item;
    return {
      ...item,
      productId: Number(productId),
      progression: Number(progression),
      isActive: true,
    };
  }


  render() {
    const { editItem } = this.state;
    const onChange = field => v => this.changeDetailItem(field, v);
    const get = field => _.get(editItem, field, '');

    return <div>
      <div className="navbar justify-content-start">
        <div className="col-auto">
          <Link className="p-4" to={this.getEndpoint()}>
            <Icon icon="chevron-left" />
          </Link>
        </div>
        <h1 className="ml-2">{this.getTitle()}</h1>
      </div>

      <div className="container pt-4">

        <Card body>
          <div className="row">

            <div className="form-group mb-0 col-sm-4">
              <label>Produkt</label>
              <Forms.Select value={get('productId')}
                placeholder="= Vyberte ="
                items={this.state.productsItems}
                onChange={onChange('productId')}
                />
            </div>
            <div className="form-group mb-0 col-sm-2">
              <label>Progrese</label>
              <Forms.Input value={get('progression')}
                onChange={onChange('progression')} />
            </div>
            <div className="form-group mb-0 col-sm-3 text-right pt-4">
              <button type="button" className="btn btn-primary font-weight-bold mr-2"
                onClick={() => this.save()}>
                Uložit
              </button>
              <Link to={this.getEndpoint(this.getId())} className="btn btn-secondary font-weight-bold">
                Zrušit
              </Link>
            </div>
          </div>
        </Card>

      </div>
    </div>;
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
    crudCache: (entity) => dispatch(crud(entity, { cache: true })),
  })
) (Edit)
