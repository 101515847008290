/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

import List from './List';
import Detail from './Detail';
import Edit from './Edit';
import New from './Edit';

import CrudRouter from 'components/CrudRouter';

export default () => {
  return <CrudRouter
    entity="mortgage/purposes"
    listComponent={List}
    detailComponent={Detail}
    editComponent={Edit}
    newComponent={New}
  />;
}
