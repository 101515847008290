import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import ConfirmDialog from 'components/ConfirmDialog.jsx';
import ProgressionValue from './components/ProgressionValue';
import ProgressionValueEdit from './components/ProgressionValueEdit';

import { crud } from 'store/api';

class Detail extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      item: null,
      newValue: false,
    }

    this.valuesApi = props.crud('progression_values');
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return this.api.find(this.getId())
      .then(( data ) => this.setState({ item: data }));
  }


  saveValueDetail(progressionValue) {
    if (!this.getId() || !progressionValue) {
      return;
    }
    let r;
    progressionValue['progressionId'] = Number(this.getId());

    if (progressionValue.id) {
      r = this.valuesApi.update(progressionValue.id, progressionValue);
    } else {
      r = this.valuesApi.create(progressionValue);
    }
      return r
        .then(() => this.setState({ isEdit: false, valueEdit: null, backupValue: null }))
        .then(() => this.loadData())
  }

  deleteValue(progressionValue) {
    if (progressionValue.id) {
      this.confirm('Smazat hodnotu progrese?', 'danger', 'Smazat')
        .then(() => this.valuesApi.delete(progressionValue.id))
        .then(() => this.loadData())
        .catch(() => {});
    }
  }

  delete() {
    this.confirm('Smazat progresi a všechny její hodnoty?', 'danger', 'Smazat')
      .then(() => this.api.delete(this.getId()))
      .then(() => window.location = this.getEndpoint('progressions'))
      .catch(() => {});
  }

  render() {
    const { item, newValue } = this.state;

    return ( item &&
      <div>

        <ConfirmDialog {...this.state.confirm} />

        <div className="navbar justify-content-start">
          <div className="col-auto">
            <Link className="p-4" to={this.getEndpoint()}>
              <Icon icon="chevron-left" />
            </Link>
          </div>
          <h1 className="ml-2">Progrese trvalých následků – {
            `${_.get(item, 'product.company.name')} ${_.get(item, 'product.name')} ${item.progression}`
          }</h1>
        </div>

        <div className="container pt-4">

          <Card body>
            <div className="row">
              <div className="form-group mb-0 col-sm-3">
                <label>Producent</label>
                <p className="mb-0">{_.get(item, 'product.company.name')}</p>
              </div>
              <div className="form-group mb-0 col-sm-4">
                <label>Produkt</label>
                <p className="mb-0">{_.get(item, 'product.name')}</p>
              </div>
              <div className="form-group mb-0 col-sm-2">
                <label>Progrese</label>
                <p className="mb-0">{item.progression}</p>
              </div>
              <div className="form-group mb-0 col-sm-3 text-right">
                {this.canDo('update') && <Link to={this.getEndpoint(`${this.getId()}/edit`)} className="btn btn-secondary font-weight-bold">
                  <Icon icon='pen' className='mr-1' />
                  Upravit
                </Link>}
              </div>
            </div>
          </Card>

          {this.getId() && <Card body className="mt-4">
            <div className="d-flex justify-content-stretch">
              <h5 className="text-uppercase font-weight-bold mb-3">
                Hodnoty progrese
                { item.values && ` (${item.values.length})` }
              </h5>
              <button className="primary-button" onClick={() => this.setState({ newValue: true })}>+ přidat</button>
            </div>

            {newValue && <table className="table col-sm-4" style={{ width: 'auto' }}>
              <thead>
                <tr>
                  <th width="100">Stupeň</th>
                  <th width="100">Hodnota</th>
                </tr>
              </thead>
              <tbody>
                <ProgressionValueEdit onClose={newValue => {
                  this.setState({ newValue: false });
                  this.saveValueDetail(newValue);
                }} />
              </tbody>
            </table>}

            <div className="row">
              {_.chunk(item.values || [], 34).map(( items, x ) => {
                return <table key={x} className="table col-sm-4" style={{ width: 'auto' }}>
                  <thead>
                    <tr>
                      <th width="100">Stupeň</th>
                      <th width="100">Hodnota</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.length && items.map((value, i) => {
                      return (
                        <ProgressionValue key={x+i} value={value}
                          onChange={(newValue) => this.saveValueDetail(newValue)}
                          onDelete={(value) => this.deleteValue(value)} />
                      )
                    })}
                  </tbody>
                </table>
              }) }
            </div>
          </Card>}

          {this.getId() && this.canDo('delete') && <div className="text-center p-3">
            <button className="btn btn-outline-danger p-2" onClick={() => this.delete()}>Smazat progresi a hodnoty</button>
          </div>}

        </div>
      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (Detail)

