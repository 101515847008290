import React from 'react';

import { classes } from 'services/utils';

const Aside = ({ on = false, className = '', children }) => (
  <div className={ classes({ 'aside': true, [className]: true }) }>
    {on && <div className="aside-overlay" />}
    <div className={ classes({ 'aside-content': true, 'on': on }) }>
      {on && children ? children : ''}
    </div>
  </div>
)

export default Aside;
