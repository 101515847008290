
export default class ArrayPaginator extends Array {

  constructor(...items) {
    super(...items);

    this.pagination = {
      page: null,
      last: null,
      limit: null,
      count: null,
    }
  }

  setPagination({ page, last, limit, count }) {
    this.pagination = {
      page, last, limit, count
    };
  }

  getPagination() {
    return this.pagination;
  }

  getPage() {
    return this.pagination.page;
  }

  getLastPage() {
    return this.pagination.last;
  }

  getLimit() {
    return this.pagination.limit;
  }

  getCount() {
    return this.pagination.count;
  }

}
