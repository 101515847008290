import React from 'react';

import CrudComponent from '../components/CrudComponent.jsx';

import { postLogout } from 'store/api';
import { removeLocalToken } from 'store/user';

class Logout extends CrudComponent {

  componentDidMount() {
    postLogout()
      .then(() => {
        removeLocalToken();
        window.location = '/';
      });
  }

  render() {
    return (
      <div className="container login-box">
        <div className="row">
          <div className="col-12 text-center">
            <p>Odhlašuji...</p>
          </div>
        </div>
      </div>
    );
  }

}


export default Logout;
