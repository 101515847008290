import _ from 'lodash';

import menu from './menu';
import menuProduction from './menuProduction';

import loans from './loans';
import routes from './routes';
import products from './products';
import acl from './acl';

const config = {
  menu,
  mortgage: loans,
  loans,
  routes,
  products,
  acl,
}

if (process.env.REACT_APP_ENV === 'production') {
  config.menu = menuProduction;
}

export const getConfig = (path) => _.get(config, path, null);

export default config;
