import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import _ from 'lodash';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import Forms from 'components/Forms.jsx';
import LabelValue from 'components/LabelValue.jsx';
import ConfirmDialog from 'components/ConfirmDialog.jsx';

import { crud } from 'store/api';
import { getConfig } from 'config';

class Edit extends CrudComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      editItem: {
        status: 'HIDDEN',
      },
    };

    this.types = props.crudCache('types');
    this.companies = props.crudCache('companies');
    this.productGroups = props.crudCache('product_groups');
  }

  componentDidMount() {
    this.loadData();

    this.types.get({ itemsPerPage: 1000, 'order[name]': 'asc' }).then(this.setCodebookData('types', ({ id, name }) => {
      return { key: id, value: name };
    }));

    this.companies.get({ status: 'VISIBLE', itemsPerPage: 1000, 'order[name]': 'asc' }).then(this.setCodebookData('companies', ({ id, name }) => {
      return { key: id, value: name };
    }));

    this.productGroups.get({  itemsPerPage: 1000, 'order[name]': 'asc' }).then(this.setCodebookData('productGroups', ({ id, name }) => {
      return { key: id, value: name };
    }));
  }

  loadData() {
    const id = this.getId();
    if (id) {
      return this.api.find(id)
        .then(( data ) => {
          let editItem;
          if (data) {
            editItem = { ...data };
          }
          this.setState({ editItem });
        });
    }
  }

  getId() {
    const { id } = _.get(this.props, 'match.params');
    return id || null;
  }

  isNew() {
    return this.getId() === null;
  }

  getTitle() {
    const { editItem } = this.state;

    return !this.isNew() && editItem ?
      `Editace produktu ${_.get(editItem, 'company.name')} ${_.get(editItem, 'name')} ` :
      'Nový produkt';
  }

  changeDetailItem(path, value, cb = null) {
    const { editItem } = this.state;
    _.set(editItem, path, value);
    return this.setState({ editItem }, cb);
  }

  prepareItemForSave(item) {
    const { isVisible, companyId, productGroupId, typeId } = item;
    return {
      ...item,
      isVisible: Boolean(isVisible),
      companyId: companyId ? parseInt(companyId) : null,
      productGroupId: productGroupId ? parseInt(productGroupId) : null,
      typeId: typeId ? parseInt(typeId) : null,
    };
  }

  render() {
    const { editItem } = this.state;
    const onChange = field => v => this.changeDetailItem(field, v);
    const get = field => _.get(editItem, field);

    return <div>

      <ConfirmDialog {...this.state.confirm} />

      <div className="navbar justify-content-start">
        <div className="col-auto">
          <Link className="p-4" to={this.getEndpoint()}>
            <Icon icon="chevron-left" />
          </Link>
        </div>
        <h1 className="ml-2">{this.getTitle()}</h1>
      </div>

      <div className="container pt-4">

        <Card body>
          <div className="row">

            <LabelValue className="col-sm-4" label="Název">
              <Forms.Input value={get('name')} onChange={onChange('name')} />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Producent">
              <Forms.Select value={get('companyId')}
                placeholder="= Vyberte ="
                items={this.state.companies}
                onChange={onChange('companyId')}
              />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Skupina">
              <Forms.Select value={get('productGroupId')}
                placeholder="= Vyberte ="
                items={this.state.productGroups}
                onChange={onChange('productGroupId')}
              />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Typ">
            <Forms.Select value={get('typeId')}
                placeholder="= Vyberte ="
                items={this.state.types}
                onChange={onChange('typeId')}
              />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Stav">
              <Forms.Select items={getConfig('products.types')} value={get('status')} onChange={onChange('status')} />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Platnost">
              <Forms.DatePicker value={get('validityDate')} onChange={onChange('validityDate')} />
            </LabelValue>
            <LabelValue className="col-sm-4" label="Expirace">
              <Forms.DatePicker value={get('expirationDate')} onChange={onChange('expirationDate')} />
            </LabelValue>

            <div className="form-group mb-0 col-sm-12 text-right pt-4">
              <button type="button" className="btn btn-primary font-weight-bold mr-2" onClick={() => this.save()}>
                Uložit
              </button>
              <Link to={this.getEndpoint(this.getId())} className="btn btn-secondary font-weight-bold">
                Zrušit
              </Link>
            </div>
          </div>
        </Card>

      </div>

    </div>;
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
    crudCache: (entity) => dispatch(crud(entity, { cache: true })),
  })
) (Edit)
