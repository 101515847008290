import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// load specific stores
import api from './api';
import cache from './cache';
import user from './user';
import acl from './acl';
import loading from './loading';


export default createStore(
  combineReducers({
    api,
    cache,
    user,
    acl,
    loading,
  }),
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
