import React from 'react';

export default class ConfirmDialog extends React.Component {

  render() {
    const { show, title, confirmLabel, cancelLabel, onYes, onNo, confirmColor = 'primary' } = this.props;
    return show &&
      <div className="confirm-modal-wrapper text-center">
        <div className="confirm-modal">
            <div className="confirm-modal__content">
                <p>{ title }</p>
            </div>
            <button className="btn btn-default" onClick={() => onNo && onNo()}>
              <strong>{ cancelLabel || 'Ne' }</strong>
            </button>
            <button className={`btn btn-${confirmColor}`} onClick={() => onYes && onYes()} style={{ marginLeft: '32px' }}>
              <strong>{ confirmLabel || 'Ano' }</strong>
            </button>
        </div>
      </div>
    ;
  }

}
