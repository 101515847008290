/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';
import Edit from './Edit';
import New from './Edit';

import CrudRouter from 'components/CrudRouter';
import { canDo } from 'services/auth';

const entity = 'buildingLoan/rates';

export default () => {
  return <CrudRouter
    entity={entity}
    apiEntity="mortgage/rates"
    listComponent={List}
    detailComponent={Detail}
    editComponent={Edit}
    newComponent={New}
  >
    {canDo(`buildingLoan/rates.create`) &&
      <Route exact path="/:id/duplicate" render={props => <Edit duplicate {...props} entity={entity} apiEntity={entity} />} />
    }

    {canDo(`buildingLoan/rates.read`) &&
      <Route exact path="/:id" render={props => <Detail {...props} entity={entity} apiEntity={entity} />} />
    }

  </CrudRouter>;
}
