import React from 'react';
import _ from 'lodash';

import Icon from 'components/Icon';
import ParameterValue from 'components/ParameterValue';

const Parameter = ({ parameter, path, onEdit, products, productsData }) => {

  const clickEdit = (e, id, parameterId, productId) => {
    e.stopPropagation();
    onEdit(id, parameterId, productId);
  }

  return <tr>
    <th className="pl-3 cursor-default">
      {_.get(parameter, 'parameter.name')}
    </th>
    {_.map(products, id => {

      const parameterData = _.get(productsData, `${id}.${parameter.id}`) || null; // null = new parameter
      return <td className="product-structure-cell p-0" key={id} onClick={e => clickEdit(e, (parameterData ? parameterData.id : null), parameter.id, id)}>
        {parameterData && <>
          <ParameterValue parameter={parameterData} />
          <span className="product-structure-cell-edit-icon"><Icon icon="pen" /></span>
        </>}
      </td>

    })}

  </tr>

}

export default Parameter;
