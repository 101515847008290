import React from 'react';
import { Link } from 'react-router-dom';

import CrudComponent from '../components/CrudComponent.jsx';
import Forms from '../components/Forms.jsx';

import { postLogin } from 'store/api';
import { setLocalToken } from 'store/user';
class Login extends CrudComponent {


  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      username: null,
      password: null,
      errorMessage: null,
    };

  }

  login() {
    this.showLoading();
    this.setState({ errorMessage: null })

    const { username, password } = this.state;
    postLogin(username, password)
      .then(({ token, expires }) => {
        console.log('Login successful, expires', token, expires);

        setLocalToken({ token, expires });

        window.location = '/';
      })
      .catch((error) => {
        const { message } = error;
        if (message) {
          this.setState({ errorMessage: message });
        }
        console.log('error', error);
      })
      .finally(() => this.hideLoading());
  }


  render() {
    return (
      <div className="container login-box">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-4">Přihlášení</h2>
                <p>
                  Přihlašovací jméno (e-mail):
                  <Forms.Input name="username"
                    value={this.state.username}
                    onEnterKey={() => this.login()}
                    onChange={(username) => this.setState({ username })} />
                </p>
                <p>
                  Heslo:
                  <Forms.Input name="password" type="password"
                    value={this.state.password}
                    onEnterKey={() => this.login()}
                    onChange={(password) => this.setState({ password })} />
                </p>
                {this.state.errorMessage && <p className="text-danger">
                  {this.state.errorMessage}
                </p> }
                <p className="text-center">
                  <button className="btn btn-lg btn-primary mt-2" onClick={() => this.login()}>Přihlásit</button>
                </p>
                <p className="text-center">
                  <Link to="/me/recover-password" className="btn btn-link">Obnovit zapomenuté heslo</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}


export default Login;
