import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import BootstrapTable from 'react-bootstrap-table-next';

import CrudComponent from 'components/CrudComponent.jsx';
import Icon from 'components/Icon.jsx';
import { crud } from 'store/api';
import { formatCodebookValue } from 'services/utils';
import { getConfig } from 'config';
import { getLoanByType } from 'config/loans';

class List extends CrudComponent {

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(props) {
    super.checkUpdatePage(props);
  }

  loadData() {
    this.showLoading();
    const params = this.getUrlParams({
      'product.typeId': getLoanByType('consumerCredit').type
    });
    return this.api.get(params)
      .then((data) => this.setData(data))
      .finally(() => this.hideLoading());
  }

  getTitle() {
    return `${getLoanByType('consumerCredit').title} / fixy ${this.getTotalFormatted()}`;
  }

  getColumns() {
    return [
      { text: 'ID', dataField: 'id', sort: true },
      { text: 'Délka', dataField: 'length', sort: true, formatter: (v) => `${v} R` },
      { text: 'Typ', dataField: 'type', sort: true, formatter: (v) => formatCodebookValue(v, getConfig('mortgage.fixes.types')) },
      { text: 'PRIBOR', dataField: 'varPriborFix', sort: true },
      { text: 'Produkt', dataField: 'product.name', sort: true, formatter: (__, {product}) => product ? `${_.get(product, 'company.shortName')} ${_.get(product, 'name')}` : '' },
      { text: '', isDummyField: true, dataField: '_actions',
          classes: 'text-right',
          formatter: (cell, row) => (<div>
            {this.canDo('read') && <Link to={this.getEndpoint(row.id)} className="btn btn-sm btn-secondary mr-3">
              <Icon icon='search' />
            </Link>}
            {this.canDo('update') && <Link to={this.getEndpoint(`${row.id}/edit`)} className="btn btn-sm btn-primary mr-3">
              <Icon icon='pen' />
            </Link>}
          </div>) }
    ];
  }

  getFilter() {
    return [
      { name: 'length', label: 'Délka', type: 'text', field: 'length' },
      { name: 'type', label: 'Typ', type: 'select', items: getConfig('mortgage.fixes.types'), field: 'type' },
      { name: 'varPriborFix', label: 'PRIBOR', type: 'text', field: 'varPriborFix' },
      { override: 'mortgageCompany', field: 'product.companyId', overrideParams: ['consumerCredit'] },
      'mortgageProduct',
    ];
  }

  render() {
    return (
      <div>
        <div className="navbar m-0 pr-2">
          <h1>{this.getTitle()}</h1>
          {this.canDo('create') && <Link to={this.getEndpoint('new')} className="primary-button">+ nový</Link>}
        </div>

        {this.renderFilter()}

        <BootstrapTable
          classes="table-sm"
          bootstrap4 hover remote
          bordered={false}
          keyField="id"
          data={this.getData()}
          columns={this.getColumns()}
          onTableChange={this.onTableChange()}
        />

        {this.renderPagination()}

      </div>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    crud: (entity) => dispatch(crud(entity)),
  })
) (List)
