// TariffCategoryParameters

/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import ProductStructure from './ProductStructure';

export default () => {
  return (
    <>
      <Router basename="/productStructure">
        <Switch>
          <Route exact path="/:type" render={(props) => <ProductStructure {...props} />} />
          <Route exact path="/" render={(props) => <ProductStructure {...props} />} />
        </Switch>
      </Router>
    </>
  );
}
