import React from 'react';

import { classes } from 'services/utils';

const LabelValue = ({ label = '', value = null, className = '', format = null, children = null }) => (
  <div className={ classes({ 'form-group': true, [className]: true }) }>
    <label>{ label }</label>
    <div>{ children ? children : (format ? format(value) : value) }</div>
  </div>
)

export default LabelValue;
