import React from 'react';
import Icon from 'components/Icon';

const EditPanel = ({ type, id, path, onEdit }) => {

  const clickEdit = (e) => {
    e.stopPropagation();
    onEdit(id, type, path);
  }

  return <div className="structure-item-edit">
    <span className="structure-button" onClick={clickEdit}>
      <Icon icon="pen" />
    </span>
  </div>

}

export default EditPanel;
